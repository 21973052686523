import clsx from 'clsx';
import { memo } from 'react';
import ProductCardVertical from '../Skeleton/ProductCardVertical';
import Slider from './index';
import styles from './styles.module.css';

function ProductPreview({ slideItems = [], isLoading = false, isMobileSeller = false }) {
  const numProductSlide = 2;
  // create array every element is an array item product with ${numProductSlide} element
  const SlideItemsGroup = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Math.ceil(slideItems.length / numProductSlide); ++i) {
    const arr = [];
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < numProductSlide; ++j) {
      const cI = numProductSlide * i;
      arr.push(slideItems[cI + j]);
    }
    SlideItemsGroup.push(arr);
  }
  const SlideItems = SlideItemsGroup.map((item, index) => (
    <div
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      className={clsx(styles.wrapperProductPreview)}
    >
      {item[0] && <div style={{ flex: 1, width: '100px' }}>{item[0]}</div>}
      {item[1] && <div style={{ flex: 1, width: '100px' }}>{item[1]}</div>}
      {item[2] && <div style={{ flex: 1, width: '100px' }}>{item[2]}</div>}
      {item[3] && <div style={{ flex: 1, width: '100px' }}>{item[3]}</div>}
      {item[4] && <div style={{ flex: 1, width: '100px' }}>{item[4]}</div>}
    </div>
  ));

  if (slideItems.length === 0 && !isLoading) return <div className={styles.emptyProduct}>Danh sách sản phẩm đang cập nhật</div>;

  return isLoading ? (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '13px', padding: '10px' }}>
      <ProductCardVertical />
      <ProductCardVertical />
    </div>
  ) : (
    <Slider slideItems={SlideItems} options={{ autoplay: false }} />
  );
}

export default memo(ProductPreview);
