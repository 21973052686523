import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { tooltipClasses } from '@mui/material';
import { isValid, isValidWithoutData } from 'clients/Clients';
import WishlistClient from 'clients/WishlistClient';
import clsx from 'clsx';
import QuantityControlTable from 'components-v2/mocules/QuantityControlTable';
import LinkComp from 'components/atoms/LinkComp';
import ProductLimitMessage from 'components/mocules/ProductLimitMessage';
import { HTTP_STATUS } from 'constants/Enums/http';
import { SVGPrice } from 'constants/SVG';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { DOMAIN_WEB_HOST } from 'sysconfig';
import { ImageFallbackProductImage, ImageFallbackStatic } from 'utils/ImageFallback';
import { getLinkProxyFile } from 'utils/ImageUtils';
import NotifyUtils from 'utils/NotifyUtils';
import calculateTimeLeftV2 from 'utils/calculateTimeLeftV2';
import { checkRule, conditionRender } from 'utils/helper';
import BorderLinearProgress from '../BorderLinearProgress';
import CountdownNewV2 from '../CountdownNewV2';
import styles from './styles.module.css';

const BG = '#F9BDC0';
const LINER = '#EA202B';
const HEIGHT_BAR = '14px !important';
const TITLE_REMOVE_WISHLIST = 'Bỏ đánh dấu sản phẩm';
const TITLE_ADD_WISHLIST = 'Đánh dấu sản phẩm quan tâm';

const PRODUCT_WIDTH_LABELS = {
  productCompany: {
    code: '2ABC',
    width: 63,
  },
};

const Promo = ({ discountPercent }) => <Typography className={styles.promo}> -{discountPercent}%</Typography>;
const ContractPrice = () => <Typography className={styles.contract}> Giá Hợp Đồng</Typography>;
const NearExpiration = ({ item, name }) => (
  <Typography style={{ color: item?.textColor, backgroundColor: item?.backgroundColor }} className={styles.nearExpiration}>
    {name}
  </Typography>
);

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#A2A0A0',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#A2A0A0',
    color: '#fff',
    fontSize: 12,
    top: '-15px',
  },
}));

const ProductTagNew = ({ data }) =>
  data?.preIconSvg ? (
    <>
      <LinkComp href={data?.link} className={styles.tagContainer}>
        <ImageFallbackStatic
          className={styles.image}
          height={30}
          width={60}
          objectFit="contain"
          fallbackSrc={getLinkProxyFile(data?.preIconSvg)}
          src={getLinkProxyFile(data?.preIconSvg)}
          alt="img"
        />
      </LinkComp>
    </>
  ) : null;

const ProductCardNew = ({ url, product, className, viewMobile, isTourGuide }) => {
  const {
    displayPriceFormated,
    salePriceFormated,
    discountPercent,
    name,
    volume,
    slug,
    campaign,
    productType,
    deal,
    errorMessageProduct = null,
    errorMessage = null,
    isContractPrice,
  } = product;

  const curTime = new Date();
  const [isWish, setIsWish] = useState(false);
  const [dealEndCampaign, setDealEndCampaign] = useState();
  const [memoizedTimeRemaining, setMemoizedTimeRemaining] = useState();
  const percentLine = Math.round((campaign?.totalSoldQuantity / campaign?.totalQuantity) * 100);
  const percentLineDeal = Math.round((deal?.totalCurrentQuantity / deal?.totalDealQuantity) * 100);
  const totalSold = campaign?.totalSoldQuantity || deal?.totalCurrentQuantity;
  const nearExpiration = product?.skuTags?.find((x) => x?.code === 'NEAR_EXPIRATION');
  const revertDisplayFormat = (price) => {
    if (price?.length > 11) {
      return price?.substring(0, 8) + '..';
    }
    return price;
  };

  useEffect(() => {
    setIsWish(product?.isWishList);
  }, []);

  useEffect(() => {
    const convertEndDay =
      productType === 'MEGA_DAY'
        ? campaign?.campaign?.endTime
        : campaign?.flashSaleTime?.find((item) => new Date(item.startTime) < curTime && new Date(item.endTime) > curTime)?.endTime;
    setDealEndCampaign(convertEndDay);
    setMemoizedTimeRemaining(calculateTimeLeftV2(convertEndDay || deal?.endTime));
  }, []);
  const handleDeleteProductWishlist = async (product) => {
    const res = await WishlistClient.deleteItemWishlist(product?.sku);
    if (isValidWithoutData(res)) {
      setIsWish(() => false);
      return NotifyUtils.success(`Đã xóa ${product?.name} khỏi danh sách quan tâm`);
    }
    return NotifyUtils.error(`Xóa sản phẩm ${product?.name} khỏi danh sách quan tâm thất bại`);
  };

  const handleUpdateWishlist = async (product) => {
    const res = await WishlistClient.updateWishlist(product?.sku);
    if (isValid(res) || res?.status === HTTP_STATUS.Existed) {
      setIsWish(() => true);
      return NotifyUtils.success(`Đã thêm ${product?.name} vào danh sách quan tâm`);
    }
    return NotifyUtils.error(`Thêm ${product?.name} vào danh sách sản phẩm quan tâm thất bại`);
  };

  const handleClickWhistList = async () => {
    if (isWish) {
      handleDeleteProductWishlist(product);
    } else {
      handleUpdateWishlist(product);
    }
  };

  const hasError = errorMessageProduct || !product.salePrice || product.status === 'STOP_SELLING' || product.salePrice === 0;
  const conditionRenderTag = (listTag) => {
    if (listTag && listTag?.length > 3) {
      return listTag?.filter((x) => x?.code !== 'NEAR_EXPIRATION').slice(0, 3);
    }
    return listTag?.filter((x) => x?.code !== 'NEAR_EXPIRATION');
  };
  const conditionMaxBuy = () => {
    return product?.consumedMaxQuantity?.maxQuantityConsumed === 0 && product?.consumedMaxQuantity?.maxQuantityType === 'PER_DAY';
  };
  const renderCondition = () => {
    if (conditionMaxBuy()) {
      return <></>;
    }
    if (isTourGuide) {
      return (
        <div id="quantity__control" className={styles.footerGuide}>
          <QuantityControlTable className={styles.quantityControlContainer} product={product} newCard />
        </div>
      );
    }
    return <QuantityControlTable className={styles.quantityControlContainer} product={product} newCard />;
  };
  const conditionRenderCountdown = () => {
    if (memoizedTimeRemaining?.days < 1) {
      return false;
    }

    return true;
  };
  return (
    <div className={clsx(styles.container, className && className)}>
      {discountPercent ? <Promo discountPercent={discountPercent} /> : null}
      {isContractPrice ? <ContractPrice /> : null}
      {nearExpiration ? <NearExpiration item={nearExpiration} name={nearExpiration?.name} /> : null}

      <div className={styles.tooltipRight} onClick={handleClickWhistList} role="button">
        <LightTooltip title={isWish ? TITLE_REMOVE_WISHLIST : TITLE_ADD_WISHLIST} arrow placement="top">
          <div className={styles?.customButton}>
            {isWish ? (
              <FavoriteIcon style={{ fontSize: 24, color: '#00b46e' }} />
            ) : (
              <FavoriteBorderIcon className={styles.icon} style={{ fontSize: 24, color: '#00000033' }} />
            )}
          </div>
        </LightTooltip>
      </div>
      <div className={styles.topContainer}>
        <LinkComp href={`${DOMAIN_WEB_HOST}/product/${slug || ''}`} className={styles.stylesImage}>
          <ImageFallbackProductImage className={styles.image} height={160} width={160} objectFit="contain" fallbackSrc={url} src={url} alt="img" />
        </LinkComp>

        <div className={styles.listTag}>
          {conditionRenderTag(product?.skuTags)?.map((x) => (
            <ProductTagNew data={x} />
          ))}
        </div>
      </div>
      <div className={styles.underContainer}>
        <div className={styles.priceContainer}>
          <Typography className={styles.price}>
            <SVGPrice displayPriceFormated={displayPriceFormated || 0} size="mdn" fillPath="#09884D" />
          </Typography>
          {discountPercent > 0 && (
            <>
              {conditionRenderCountdown() ? (
                <Typography className={styles.pricePromo}>
                  <SVGPrice displayPriceFormated={revertDisplayFormat(salePriceFormated) ?? 0} size="smn" fillPath="#333333" line />
                </Typography>
              ) : (
                <div className={clsx(styles.dateExpired, viewMobile && styles.mobileExpired)}>
                  <CountdownNewV2 targetDate={dealEndCampaign || deal?.endTime} hasMoreText />
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.titleContainer}>
          <Link href={`${DOMAIN_WEB_HOST}/product/${slug || ''}`}>
            <a className={styles.titleWrapper} padding="0px">
              {product?.skuLabels
                ? product.skuLabels.map((label) => {
                    const { productCompany } = PRODUCT_WIDTH_LABELS;
                    const width = label.code === productCompany.code ? productCompany.width : 25;

                    return (
                      <div key={label?.code} className={styles.labelImage}>
                        <ImageFallbackStatic
                          width={width}
                          height={18}
                          objectFit="contain"
                          fallbackSrc={getLinkProxyFile(label?.preIconSvg)}
                          src={getLinkProxyFile(label?.preIconSvg)}
                          alt="img"
                        />
                      </div>
                    );
                  })
                : null}
              <span>{name}</span>
            </a>
          </Link>
          <Typography className={styles.countPerItem}>{volume || ''}</Typography>
        </div>
        <div className={styles.progressContainer}>
          <div className={styles.progressBuy}>
            {productType !== 'NORMAL' ? (
              <>
                <BorderLinearProgress
                  value={percentLine || percentLineDeal || 5}
                  variant="determinate"
                  viewMobile={viewMobile}
                  width="100%"
                  height={HEIGHT_BAR}
                  bgColor={BG}
                  bgLiner={LINER}
                  linerRadius={0}
                />
                <Typography className={styles.buyCount}>
                  {percentLineDeal ? (
                    <>{conditionRender(percentLineDeal) ? checkRule(percentLineDeal) : `Đã bán ${totalSold}`}</>
                  ) : (
                    <>{conditionRender(percentLine) ? checkRule(percentLine) : `Đã bán ${totalSold}`}</>
                  )}
                </Typography>
              </>
            ) : null}
          </div>
          {!hasError && (
            <ProductLimitMessage
              consumedMaxQuantity={product.consumedMaxQuantity}
              unit={product.unit}
              page="products"
              className={styles.limitMessage}
              isNewCard
            />
          )}
        </div>
        <div className={styles.controlQuantity}>
          {/* <AnotherSeller /> */}
          {hasError && <Typography className={styles.error}>{errorMessageProduct || errorMessage || 'Đang cập nhật giá'}</Typography>}
        </div>
      </div>

      {!hasError && renderCondition()}
    </div>
  );
};

export default ProductCardNew;

