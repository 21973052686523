import { Button, Container } from '@material-ui/core';
import MiniBannerBlock from 'components/organisms/MiniBannerBlock';
import { useAuth } from 'context/Auth';
import { CompanyIntroduction } from '../Company';
import styles from './styles.module.css';

const BrandedGoods = () => {
  const { toggleSignUp } = useAuth();
  return (
    <div className={styles.container}>
      <Container className={styles.boxContainer}>
        <CompanyIntroduction titleText="Ưu đãi hàng hãng" />
        <MiniBannerBlock isMobile={false} />
        <div className={styles.boxBtn}>
          <Button onClick={toggleSignUp}>Nhận ngay ưu đãi </Button>
        </div>
      </Container>
    </div>
  );
};

export default BrandedGoods;
