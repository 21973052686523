import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { BANNER1, BANNER2, BANNER3 } from 'constants/Images';
import { createSettingsSliderBanner } from 'constants/data/setting-slider';
import Link from 'next/link';
import { memo, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ImageFallbackBanner } from 'utils/ImageFallback';
import InfoBanner2 from '../InfoBanner2';
import styles from './styles.module.css';

const mapImg = [BANNER1, BANNER2, BANNER3];

export const ProgressBar = ({ currentIndex, setCurrentIndex, maxIndex, time, percentTime, setPercentTime }) => {
  const intervalRef = useRef();

  useEffect(() => {
    const tick = () => {
      setPercentTime((prev) => {
        if (prev >= 100) {
          const nextIndex = currentIndex + 1 > maxIndex ? 0 : currentIndex + 1;
          setCurrentIndex(nextIndex);
          return 0;
        }
        return prev + 1;
      });
    };
    intervalRef.current = setInterval(tick, time * 10);
    return () => clearInterval(intervalRef.current);
  }, [currentIndex, maxIndex, time]);

  const handleClick = (index) => {
    clearInterval(intervalRef.current);
    setCurrentIndex(index);
  };

  return (
    <div className={styles.progressBarContainer}>
      {Array.from({ length: maxIndex + 1 }).map((_, index) => (
        <div className={styles.item} onClick={() => handleClick(index)} key={index}>
          <div
            className={styles.progressBar}
            style={{ width: `${currentIndex === index ? percentTime : 0}%`, background: '#08ac60', transition: 'width 0.1s ease-in' }}
          />
        </div>
      ))}
    </div>
  );
};
const BannerSliderV2 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [percentTime, setPercentTime] = useState(0);
  const maxIndex = mapImg.length - 1;
  const sliderRef = useRef(null);
  const settings = createSettingsSliderBanner(setCurrentIndex, setPercentTime);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currentIndex);
    }
  }, [currentIndex]);

  return (
    <Container className={styles.boxContainer}>
      <Grid container className={styles.boxGrid}>
        <Grid item xs={12} sm={8}>
          <div className={styles.boxSlider}>


            <Slider ref={sliderRef} {...settings}>
              {mapImg.map((label, index) => (
                <div className={styles.gridImagesWrapper} key={index}>
                  <div className={styles.gridImages}>
                    <div className={styles.bannerMobileBlock}>
                      {index === 0 || index === 2 ? (
                        <Link href="https://www.youtube.com/watch?v=Dr0Rs7cQclg">
                          <a target="_blank" rel="noopener noreferrer">
                            <ImageFallbackBanner src={label} layout="fill" width={2100} quality={100} />
                          </a>
                        </Link>
                      ) : (
                        <ImageFallbackBanner src={label} layout="fill" width={2100} quality={100} />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <ProgressBar
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              maxIndex={maxIndex}
              time={10}
              setPercentTime={setPercentTime}
              percentTime={percentTime}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className={styles.bannerWrap}>
          <InfoBanner2 />
        </Grid>
      </Grid>
    </Container>
  );
};

export default memo(BannerSliderV2);
