export const ENUMS_MINI_BANNER_CAMPAIGN_PLATFORM = {
  WEB: 'WEB',
  MOBILE_WEB: 'MOBILE_WEB',
};

export const ENUMS_MINI_BANNER_IMAGE_LAYOUT = {
  MINI_BANNERS_SIZE_5: {
    value: 'MINI_BANNERS_SIZE_5',
    quantity: 5,
    label: '5 mini banner',
  },
  MINI_BANNERS_SIZE_4: {
    value: 'MINI_BANNERS_SIZE_4',
    quantity: 4,
    label: '4 mini banner',
  },
};
