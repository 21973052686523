import Button from '@material-ui/core/Button';
import LinkComp from 'components/atoms/LinkComp';
import { BRAND_LOGO_SVG } from 'constants/Images';
import { useAuth } from 'context/Auth';
import { memo } from 'react';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import DownloadBar from '../Header/DownloadBar';
import styles from './styles.module.css';

const LinkLogo = memo(() => (
  <LinkComp href="/home">
    <ImageFallbackStatic className={styles.logo} href="/home" src={BRAND_LOGO_SVG} width="127px" height="22px" layout="fixed" />
  </LinkComp>
));

const HeaderMobileV2 = memo(({ title = '', isMobile }) => {
  const { isAuthenticated, toggleLogin, toggleSignUp } = useAuth();

  return (
    <>
      <DownloadBar />
      {!isAuthenticated ? (
        <div className={styles.row}>
          <div className={styles.logo1}>
            <LinkLogo className={styles.logoIcon} />
          </div>
          <div className={styles.buttonParent}>
            <Button className={styles.button} onClick={toggleSignUp}>
              Đăng Ký
            </Button>
            <Button className={styles.button1} onClick={toggleLogin}>
              Đăng Nhập
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
});

export default HeaderMobileV2;
