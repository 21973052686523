import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { STATIC_STORAGE } from 'sysconfig';
import { ImageFallbackBanner } from 'utils/ImageFallback';
import styles from '../styles.module.css';

export const introCards = [
  {
    name: 'Sản phẩm đa dạng',
    decs: 'Hơn 35.000 sản phẩm thuốc chính hãng, thực phẩm chức năng, vật tư và thiết bị y tế đến từ nhà máy, công ty dược phẩm và hơn 2000 nhà phân phối uy tín.',
    url: '/img/new//TT1_New.jpg',
    id: 1,
  },
  {
    name: 'Mua hàng tiện lợi',
    decs: 'Đặt hàng nhanh chóng trong vòng 5 giây trên một nền tảng duy nhất nhưng vẫn đảm bảo chất lượng với mức giá cạnh tranh, khuyến mãi mỗi ngày.',
    url: '/img/new//TT2_New.jpg',
    id: 2,
  },
  {
    name: 'Giao hàng toàn quốc',
    decs: 'Mạng lưới giao hàng rộng rãi trên khắp 63 tỉnh thành với 3 kho trung tâm xử lý đơn hàng, 120 hubs giao hàng trên toàn quốc.',
    url: '/img/new//TT3_New.jpg',
    id: 3,
  },
  {
    name: 'Đội ngũ chuyên nghiệp',
    decs: ' Chăm sóc khách hàng 1:1, tư vấn miễn phí, tận tình và chu đáo, đem lại trải nghiệm mua hàng cho quý khách tốt nhất.',
    url: '/img/new//TT4_New.jpg',
    id: 4,
  },
];

function IntroCard() {
  const aspectRatio = 285 / 220;

  return (
    <Container className={styles.boxContainer}>
      <Grid container className={styles.card}>
        {introCards.map((card) => (
          <Grid item xs={12} sm={3} key={card.id}>
            <div>
              <div className={styles.introduction_cards}>
                <div style={{ position: 'relative', width: '100%', height: 0, paddingBottom: `${100 / aspectRatio}%`, pointerEvents: 'none' }}>
                  <ImageFallbackBanner src={`${STATIC_STORAGE}${card?.url}`} layout="fill" objectFit="cover" alt={card.name} />
                </div>
                <div className={styles.infomations}>
                  <div className={styles.name}>{card.name}</div>
                  <div className={styles.decs}>
                    <span className={styles.span1}>{card.decs}</span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default IntroCard;
