import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { COLOR_STORE } from 'constants/Icons';
import { TAG_HOA_DON_NHANH } from 'constants/Icons/tag';
import { ImageTag } from 'constants/TagTypeProps';
import { useAuth } from 'context/Auth';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { ImageFallbackBanner, ImageFallbackStatic } from 'utils/ImageFallback';
import gtag from 'utils/gtag';
import { CompanyIntroduction } from '../Company';

const stepLogin = [
  {
    id: 1,
    steps: 'Nhấp vào đăng ký',
    images: `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/PHONE1_NEW.jpg`,
    contents: 'Đăng ký tài khoản <a>tại đây</a>, hoặc quét mã QR',
    iviText: 'để tải xuống ứng dụng thuocsi.vn',
    iviIMG: `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/qr.jpg`,
  },
  {
    id: 2,
    steps: 'Nhập thông tin',
    images: `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/PHONE2_NEW.jpg`,
    contents: 'Điền đầy đủ các thông tin yêu cầu: Tên nhà thuốc, phòng khám, tỉnh/thành phố, quận/huyện, phường/xã, số nhà - tên đường.',
  },
  {
    id: 3,
    steps: 'Bổ sung thông tin doanh nghiệp',
    images: `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/PHONE3_NEW.jpg`,
    contents: 'Cung cấp thông tin giấy tờ cần thiết bao gồm:',
    iviText: 'Sau đó cập nhật đầy đủ thông tin mã số thuế, địa chỉ, tỉnh/ thành phố, quận/ huyện, phường/xã.',
    iviiText: 'Giấy chứng nhận đăng ký doanh nghiệp. <br /> Giấy chứng nhận đủ điều kiện kinh doanh dược. <br /> Hồ sơ GDP/ GPP/ GSP.',
  },
  {
    id: 4,
    steps: 'Nhấp vào cập nhật',
    images: `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/PHONE4_NEW.jpg`,
    contents:
      'Sau khi bổ sung thông tin doanh nghiệp hoàn tất, bạn nhấp vào “Cập nhật” và liên hệ với bộ phận CSKH qua <a href="tel:18002038">1800 2038</a> để được hỗ trợ kích hoạt tài khoản trong vòng 24 giờ. ',
  },
];

const useStyles = makeStyles((theme) => ({
  blockStep: {
    background: 'linear-gradient(171.58deg, rgba(210, 243, 223, 0.1) 0%, rgba(21, 169, 89, 0.1) 29.1%)',
    padding: '20px 0',
    position: 'relative',
    zIndex: 2,
  },
  blockContainer: {
    maxWidth: '1440px',
    padding: '16px 68px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  completedStep: {
    color: theme.palette.grey[900],
  },
  arrowIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '52px',
    height: '52px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);',
    borderRadius: '40px',
    zIndex: 1,
    '& .MuiIconButton-label ': {
      color: '#08AC60',
    },
  },
  stepperContainer: {
    position: 'relative',
    paddingTop: '20px',
    '& .MuiIconButton-root:hover': {
      backgroundColor: '#08AC60',
      '& .MuiIconButton-label ': {
        color: '#FFFFFF',
      },
    },
    '& .MuiIconButton-root.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.38)',
      '& .MuiIconButton-label ': {
        color: '#FFFFFF',
      },
    },
    '& .MuiStepper-root': {
      height: '100%',
    },
    '& .MuiStepContent-root': {
      border: 'none',
      marginTop: '0',
    },

    '& .MuiCollapse-wrapperInner': {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
  },
  stepIcon: {
    '& .MuiStepIcon-root': {
      width: '60px',
      height: '60px',
    },
  },
  stepConnector: {
    '& .MuiStepConnector-vertical': {
      marginLeft: '30px',
      padding: '8px 0',
    },
    '& .MuiStepConnector-lineVertical': {
      minHeight: '40px',
    },
  },
  stepName: {
    '& .MuiStepLabel-label': {
      color: '#797979',
      fontFamily: 'ggsm',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: '#09884D',
      fontFamily: 'ggsm',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vw * (764 / 1304))',
    maxWidth: '300px',
    maxHeight: 'calc(300px * (600 / 300))',
    pointerEvents: 'none',
  },

  imageCaption: {
    position: 'absolute',
    width: '100',
    height: '300px',
    left: '24px',
    bottom: '24px',
    background: 'rgba(255, 255, 255, 0.9)',
    zIndex: 1,
    padding: '16px 24px',
  },
  imageCaptionActive: {
    animation: '$slide-up 1s',
    animationFillMode: 'both',
  },
  '@keyframes slide-up': {
    '0%': {
      transform: 'translateY(100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  root: {
    '& .MuiAccordionSummary-root': {
      padding: '8px',
    },
    '& .MuiAccordionSummary-content p': {
      fontFamily: 'ggsr',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '23px',
      letterSpacing: '0px',
      textAlign: 'left',
    },
    '& .MuiAccordionSummary-content:hover p': {
      color: '#09884d ',
    },
    '& ul': {
      margin: '0',
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: '0',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0',
    },
  },
  button: {
    flex: '1',
    marginLeft: '4px',
    background: '#C4C4C4',
    color: '#79797979',
    '& .MuiButton-label': {
      zIndex: 1,
      fontFamily: 'ggsr',
      fontWeight: 500,
      fontSize: '16px',
      color: '#79797979',
    },
  },
  active: {
    background: 'var(--gradient-green, linear-gradient(270deg, #08AC60 18.75%, #26BF78 84.38%))',
    color: '#FFFFFF',
  },
  stepContent: {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    paddingLeft: '35px',
  },
  stepContent1: {
    display: 'flex',
    gap: '4px',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingLeft: '35px',
    marginTop: '8px',
  },
  btn: {
    paddingLeft: '35px',
    marginTop: '8px',
  },
  expanded: {
    color: theme.palette.primary.main,
  },
  decimalList: {
    margin: '0',
    listStyle: 'decimal',
    '& ul li': {
      color: '#797979',
      fontFamily: 'ggsr',
      fontSize: '16px',
      fontWeight: 400,
    },
  },
  textTitle: {
    fontFamily: 'ggsr',
    fontSize: '16px',
    color: '#000000',
    fontWeight: 400,
    '& button': {
      color: '#0e1983',
    },
    '& ul li': {
      color: '#797979',
      fontFamily: 'ggsr',
      fontSize: '16px',
      fontWeight: 400,
    },
    '& a': {
      cursor: 'pointer',
    },
  },
  tag: {
    display: 'inline-flex',
    padding: '2px 8px',
    alignItems: 'center',
    gap: '4px',
    background: '#F2F4FA',
    borderRadius: '2px',
  },
  store: {
    color: '#B98F0F',
    fontFamily: 'ggsm',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  textDesc: {
    fontFamily: 'ggsr',
    fontSize: '16px',
    color: '#797979',
  },
  btnNext: {
    borderRadius: '30px',
    padding: '7px',
    textTransform: 'uppercase',
    width: '125px',
    height: '32px',
    color: '#09884d',
    border: '1px solid #09884d',
    boxShadow: 'none',
  },
  textLink: {
    color: '#0E1983',
    fontFamily: 'ggsr',
    fontSize: '16px',
    fontWeight: 400,
    textDecorationLine: 'underline',
    marginTop: '12px',
  },
  boxContainer: {
    maxWidth: '1304px',
    padding: '20px 0',
    '@media (max-width:1280px)': {
      padding: '20px 40px',
    },
  },
}));

export default function Questions() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentStepOption, setCurrentStepOption] = useState(stepLogin[0]);
  const [selectedImage, setSelectedImage] = useState(currentStepOption.images[0]);
  const { toggleSignUp } = useAuth();
  const chat = document.getElementById('openChat');
  const handleOpenchat = () => {
    if (chat) {
      const img = chat?.getElementsByTagName('img');
      img[0]?.click();
    }
  };

  const [expanded, setExpanded] = useState({
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
    panel5: false,
    panel6: false,
    panel7: false,
    panel8: false,
  });

  const handleAccordionChange = (panel) => (isExpanded) => {
    setExpanded((prevExpanded) => {
      const newExpanded = { ...prevExpanded };

      if (newExpanded[panel] && isExpanded) {
        newExpanded[panel] = false;
      } else {
        Object.keys(newExpanded).forEach((key) => {
          newExpanded[key] = key === panel ? isExpanded : false;
        });
      }

      return newExpanded;
    });
  };

  useEffect(() => {
    setSelectedImage(currentStepOption.images[activeStep]);
  }, [activeStep, currentStepOption, activeIndex]);

  const handleStepOptionChange = (option) => () => {
    setCurrentStepOption(option);
    setActiveStep(0);
    setActiveIndex(0);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    setActiveIndex(step);
  };

  const handleNextStep = () => {
    if (activeStep < currentStepOption.steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setActiveIndex((prevActiveIndex) => prevActiveIndex + 1);
    }
  };

  const handlePreviousStep = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setActiveIndex((prevActiveIndex) => prevActiveIndex - 1);
    }
  };
  const stepIconProps = (index) => ({
    classes: {
      text: activeStep === index ? classes.completedStep : '',
    },
    style: {
      width: '60px',
      height: '60px',
    },
  });
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef();

  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.addEventListener('click', () => {
        toggleSignUp();
      });
    }
  }, []);

  return (
    <Container className={classes.boxContainer}>
      <CompanyIntroduction titleText="Các câu hỏi thường gặp" />
      <div className={classes.root}>
        <Accordion elevation={0} expanded={expanded.panel1} onChange={handleAccordionChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={expanded.panel1 ? classes.expanded : ''}
          >
            <Typography>Cách đăng ký tài khoản tại thuocsi.vn</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container className={classes.stepperContainer}>
              <Grid item xs={12} sm={4}>
                <div className={classes.imageContainer} ref={imageRef}>
                  <ImageFallbackBanner src={stepLogin[activeStep].images} alt="step" layout="fill" objectFit="contain" />
                </div>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Stepper activeStep={activeStep} nonLinear orientation="vertical" className={classes.stepConnector}>
                  {stepLogin.map((step, index) => (
                    <Step key={step.id} className={classes.stepIcon}>
                      <StepButton onClick={handleStep(index)} className={classes.stepName}>
                        <StepLabel StepIconProps={stepIconProps(index)}>{step.steps}</StepLabel>
                      </StepButton>
                      <StepContent>
                        <div className={activeStep > 0 ? classes.stepContent1 : classes.stepContent}>
                          <div ref={buttonRef} className={classes.textTitle} dangerouslySetInnerHTML={{ __html: `${step.contents}` }} />
                          {step.iviiText && (
                            <ul>
                              {step.iviiText.split('<br />').map((item, i) => (
                                <li key={i}>
                                  <span className={classes.textTitle}>{item}</span>{' '}
                                </li>
                              ))}
                            </ul>
                          )}
                          {step.iviText && activeStep === 2 && <Typography className={classes.textTitle}>{step.iviText}</Typography>}
                          {step.iviIMG && <Image src={step.iviIMG} height={68} width={68} />}
                          {step.iviText && activeStep === 0 && <Typography className={classes.textTitle}>{step.iviText}</Typography>}
                        </div>
                        <div className={classes.btn}>
                          {activeStep > 0 && (
                            <Button className={classes.btnNext} onClick={handlePreviousStep}>
                              Trở lại
                            </Button>
                          )}
                          {activeStep === stepLogin.length - 1 ? (
                            ''
                          ) : (
                            <Button
                              className={classes.btnNext}
                              onClick={handleNextStep}
                              variant="contained"
                              color="primary"
                              style={{ marginLeft: '10px', color: '#fff' }}
                              disabled={activeStep === stepLogin.length - 1}
                            >
                              Tiếp tục
                            </Button>
                          )}
                        </div>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} expanded={expanded.panel2} onChange={handleAccordionChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={expanded.panel2 ? classes.expanded : ''}
          >
            <Typography>Không đăng nhập được</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <ul>
                <li>
                  <Typography className={classes.textTitle}>
                    Tài khoản bị xóa khi:
                    <span className={classes.textDesc}>
                      &nbsp;tài khoản mới tạo không cập nhật đầy đủ thông tin doanh nghiệp và các giấy tờ cần thiết, hệ thống sẽ tự động vô hiệu hóa
                      tài khoản.
                    </span>
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.textTitle}>
                    Tài khoản tạm ngưng hoạt động khi:{' '}
                    <span className={classes.textDesc}>
                      &nbsp;tài khoản trong vòng 3 tháng không đặt hàng, hệ thống sẽ tự động vô hiệu hóa tài khoản.
                    </span>
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.textTitle}>
                    Nếu quý khách có nhu cầu đặt hàng, vui lòng nhấn nút <Button onClick={handleOpenchat}>“Hỗ Trợ”</Button> hoặc gọi đến{' '}
                    <Link href="tel:18002038" onClick={() => gtag.callHotline()} legacyBehavior={false}>
                      1800 2038
                    </Link>
                  </Typography>
                </li>
              </ul>
              <div className={classes.textLink}>
                <Link href="https://thuocsihotro.helpwise.help/collections/206670-cu-hi-v-ti-khon">Xem thêm câu hỏi về tài khoản</Link>
              </div>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} expanded={expanded.panel3} onChange={handleAccordionChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={expanded.panel3 ? classes.expanded : ''}
          >
            <Typography>Cách xuất hóa đơn đỏ</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <ul className={classes.decimalList}>
                  <li>
                    <Typography className={classes.textTitle}>
                      Sản phẩm được xuất hóa đơn đỏ là sản phẩm có tag&nbsp;
                      <span className={classes.tag}>
                        <ImageTag src={TAG_HOA_DON_NHANH} />
                        Hóa Đơn Nhanh
                      </span>
                    </Typography>
                  </li>
                  <li>
                    <Typography className={classes.textTitle}>Thời gian xuất hóa đơn đỏ:</Typography>
                    <ul>
                      {' '}
                      <li>
                        Nhà bán hàng là{' '}
                        <span>
                          {' '}
                          <ImageFallbackStatic width="14" height="16" src={COLOR_STORE} /> MedX
                        </span>{' '}
                        hoặc <span className={classes.store}>Hàng Hãng</span> : xuất hóa đơn sau khi đơn hàng giao thành công
                      </li>
                      <li>Các nhà bán hàng còn lại: xuất hóa đơn từ 8-10 ngày sau khi đơn hàng giao thành công.</li>
                    </ul>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} className={classes.textLink}>
                <Link href="https://thuocsihotro.helpwise.help/articles/214772-xut-ha-n--ti-thuocsivn" className={classes.textLink}>
                  Xem thêm thao tác về yêu cầu xuất hóa đơn
                </Link>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} expanded={expanded.panel4} onChange={handleAccordionChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={expanded.panel4 ? classes.expanded : ''}
          >
            <Typography>Thời gian giao hàng</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <ul className={classes.decimalList}>
                  <li>
                    <Typography className={classes.textTitle}>Áp dụng cho khu vực TP. Hồ Chí Minh và Hà Nội:</Typography>
                    <ul>
                      <li>Đặt hàng trước 9h00: dự kiến giao hàng trong ngày.</li>
                      <li>Đặt hàng từ 9h00 - 14h00: dự kiến giao hàng trong sáng ngày hôm sau.</li>
                      <li>Đặt hàng sau 14h00: dự kiến giao hàng trong ngày hôm sau.</li>
                    </ul>
                  </li>
                  <li>
                    <Typography className={classes.textTitle}>Áp dụng cho các tỉnh, khu vực khác:</Typography>
                    <ul>
                      <li>Giao hàng sau 3-5 ngày sau khi đặt hàng thành công</li>
                    </ul>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} className={classes.textLink}>
                <Link href="https://thuocsihotro.helpwise.help/collections/206673-cu-hi-v-vn-chuyn" className={classes.textLink}>
                  Xem thêm câu hỏi về vận chuyển
                </Link>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} expanded={expanded.panel5} onChange={handleAccordionChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={expanded.panel5 ? classes.expanded : ''}
          >
            <Typography>Chi phí giao hàng</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <ul>
                  <li>
                    <Typography className={classes.textTitle}>Miễn phí giao hàng cho đơn hàng đầu tiên.</Typography>
                  </li>
                  <li>
                    <Typography className={classes.textTitle}>
                      Áp dụng cho đơn hàng đặt khu vực TP. Hồ Chí Minh, Hà Nội: miễn phí giao hàng cho đơn từ 1.500.000đ.
                    </Typography>
                  </li>
                  <li>
                    <Typography className={classes.textTitle}>
                      Áp dụng cho đơn hàng tại các tỉnh khác: miễn phí giao hàng cho đơn từ 2.000.000đ.
                    </Typography>
                  </li>
                  <li>
                    <Typography className={classes.textTitle}>
                      Nếu đơn hàng chưa đủ mức giá trị để được miễn phí giao hàng, sẽ bị tính phụ phí 50.000đ.
                    </Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} className={classes.textLink}>
                <Link href="https://thuocsihotro.helpwise.help/collections/206673-cu-hi-v-vn-chuyn">Xem thêm câu hỏi về vận chuyển</Link>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} expanded={expanded.panel6} onChange={handleAccordionChange('panel6')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={expanded.panel6 ? classes.expanded : ''}
          >
            <Typography>Quy định về thanh toán</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <Typography>Có 2 phương thức thanh toán sau:</Typography>
                <ul className={classes.decimalList}>
                  <li>
                    <Typography className={classes.textTitle}>Thanh toán tiền mặt khi nhận hàng (COD).</Typography>
                  </li>
                  <li>
                    <Typography className={classes.textTitle}>Thanh toán chuyển khoản.</Typography>
                  </li>
                </ul>
                <Typography className={classes.textTitle}>Quy định về nhận lại phần chênh lệch chuyển khoản:</Typography>
                <ul>
                  <li>
                    <Typography className={classes.textTitle}>Khi khách hàng thanh toán bằng phương thức chuyển khoản</Typography>
                  </li>
                  <li>
                    <Typography className={classes.textTitle}>Áp dụng trong trường hợp thuocsi.vn không giao đủ số lượng sản phẩm đã đặt.</Typography>
                  </li>
                  <li>
                    <Typography className={classes.textTitle}>
                      Đối với các đơn hàng không giao đủ số lượng sản phẩm, hệ thống sẽ tự động chuyển lại phần chênh lệch với điều kiện đã cập nhật
                      thông tin thanh toán (Tên tài khoản, số tài khoản).
                    </Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} className={classes.textLink}>
                <Link href="https://thuocsihotro.helpwise.help/collections/206672-cu-hi-v-thanh-ton-v-ha-n">Xem thêm câu hỏi về thanh toán</Link>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} expanded={expanded.panel7} onChange={handleAccordionChange('panel7')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={expanded.panel7 ? classes.expanded : ''}
          >
            <Typography>Theo dõi đơn hàng</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Typography className={classes.textTitle}>Có 2 phương thức để theo dõi đơn hàng:</Typography>

              <Grid item xs={12}>
                <ul className={classes.decimalList}>
                  <li>
                    <Typography className={classes.textTitle}>Truy cập vào mục “Đơn hàng của tôi” để theo dõi trạng thái đơn hàng.</Typography>
                  </li>
                  <li>
                    <Typography className={classes.textTitle}>Nhấp vào từng đơn hàng để theo dõi trạng thái.</Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} className={classes.textLink}>
                <Link href="https://thuocsihotro.helpwise.help/collections/206673-cu-hi-v-vn-chuyn">Xem thêm câu hỏi về vận chuyển</Link>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} expanded={expanded.panel8} onChange={handleAccordionChange('panel8')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={expanded.panel8 ? classes.expanded : ''}
          >
            <Typography>Chính sách trả hàng</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <ul>
                  <li>
                    <Typography className={classes.textTitle}>
                      Khách hàng có thể gửi yêu cầu trả hàng trong vòng 05 ngày kể từ lúc đơn hàng được giao hàng thành công.
                    </Typography>
                  </li>
                  <li>
                    <Typography className={classes.textTitle}>
                      Đối với các sản phẩm đông lạnh (trừ trường hợp chưa nhận được hàng), khách hàng cần gửi yêu cầu trả trong vòng 24 giờ kể từ lúc
                      đơn hàng được giao thành công.
                    </Typography>
                  </li>
                  <li>
                    <Typography className={classes.textTitle}>
                      Đối với các sản phẩm trả hàng liên quan đến chất lượng thì thời hạn yêu cầu trả trong vòng 30 ngày kể từ lúc đơn hàng được giao
                      thành công.
                    </Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} className={classes.textLink}>
                <Link href="https://thuocsihotro.helpwise.help/collections/206672-cu-hi-v-thanh-ton-v-ha-n">Xem thêm câu hỏi về thanh toán</Link>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
}
