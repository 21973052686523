import { getFirst, isValid } from 'clients/Clients';
import { useCart } from 'context/Cart/CartContext';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'utils/debounce';

const UPDATE = 'update';
const REMOVE = 'remove';
const REGEX_NUMBER = new RegExp('^[0-9]+$');

const useCartControlTable = (product, refValue) => {
  const refInstance = refValue;

  const [currentValue, setCurrentValue] = useState(0);
  const [valueChange, setValueChange] = useState(0);
  const [isShowModalRemove, setIsShowModalRemove] = useState(false);
  const { removeCartItem, updateCartItem } = useCart();

  const productQuantity = product.quantity || 0;
  const updateCart = async (q, isReload = true) => {
    const response = await updateCartItem({ product, q: parseFloat(q) }, isReload);
    if (isValid(response) && refInstance.current) {
      const { quantity: quantityResponse = null } = getFirst(response, {});
    } else {
      setCurrentValue(0);
    }
  };
  const handleCart = (val, updateType) => {
    switch (updateType) {
      case UPDATE:
        updateCart(val);
        break;
      case REMOVE:
        removeCartItem(product, true);
        break;
      default:
        break;
    }
  };

  const handler = useCallback(
    debounce((val, updateType) => handleCart(val, updateType), 400),
    [product],
  );
  const confirmRemove = () => {
    refInstance.current.value = 0;
    setValueChange(0);
    setIsShowModalRemove(false);
    return handler(0, REMOVE);
  };
  const closeModal = () => {
    refInstance.current.value = 1;
    setValueChange(1);
    handler(1, UPDATE);
    setIsShowModalRemove(false);
  };
  const handleDecrease = (e) => {
    e.stopPropagation();
    const newValue = refInstance.current.value - 1;
    if (newValue >= 0) {
      if (newValue === 0) {
        return setIsShowModalRemove(true);
      }
      refInstance.current.value = newValue;
      setValueChange(newValue);
      return handler(newValue, UPDATE);
    }
    return null;
  };

  const handleIncrease = (e) => {
    e.stopPropagation();
    const newValue = +refInstance.current.value + 1;
    if (newValue > product?.consumedMaxQuantity?.maxQuantityConsumed) return null;
    setValueChange(newValue);
    refInstance.current.value = newValue;
    return handler(newValue, UPDATE);
  };
  const handleBlurInput = (e) => {
    const { value } = e.target;
    if (+value !== +currentValue) {
      if (+value && REGEX_NUMBER.test(value)) {
        return handler(value, UPDATE);
      }
    }
    if (+value === 0 && +value !== valueChange) {
      return setIsShowModalRemove(true);
    }
    return null;
  };

  const handleChangeInput = (e) => {
    const { value } = e.target;
    if (value === '00') {
      refInstance.current.value = 0;
      return;
    }
    if (value && refInstance.current.value !== '0') {
      if (+value > +product?.consumedMaxQuantity?.maxQuantityConsumed || +value > 10000) {
        const valueData = +product?.consumedMaxQuantity?.maxQuantityConsumed || 10000;
        refInstance.current.value = valueData;
        setValueChange(valueData);
      } else if (REGEX_NUMBER.test(+value)) {
        refInstance.current.value = parseInt(value, 10);
        setValueChange(parseInt(value, 10));
      } else {
        refInstance.current.value = 0;
        setValueChange(0);
      }
    } else {
      refInstance.current.value = 0;
    }
  };
  useEffect(() => {
    setCurrentValue(productQuantity);
    setValueChange(productQuantity);
    refInstance.current.value = productQuantity;
  }, []);
  return [handleIncrease, handleBlurInput, handleChangeInput, handleDecrease, confirmRemove, closeModal, isShowModalRemove, valueChange];
};
export default useCartControlTable;
