import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import { useAuth } from 'context/Auth';
import { useEffect, useRef, useState } from 'react';
import { ImageFallbackBanner } from 'utils/ImageFallback';

const aspectRatio = 952 / 600;

const stepOptions = [
  {
    id: 'A',
    steps: ['Điền thông tin doanh nghiệp', 'Kích hoạt tài khoản', 'Tra cứu sản phẩm', 'Nhận ưu đãi đơn đầu'],
    images: [
      `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/1_New.jpg`,
      `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/2_New.jpg`,
      `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/3_New.jpg`,
      `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/4_New.jpg`,
    ],
    contents: [
      `<h4 class='title'>Điền đầy đủ thông tin doanh nghiệp và cung cấp các loại hồ sơ sau:</h4>
		 <p class='info'>Giấy chứng nhận:</p>
		 <ul class='decimalList'>
		 <li>Thực hành tốt phân phối thuốc (GDP).</li>
		 <li>Thực hành tốt cơ sở bán lẻ thuốc (GPP).</li>
		 <li>Thực hành tốt bảo quản thuốc (GSP).</li>
		 <li>Đủ điều kiện kinh doanh dược.</li>
		 <li>Đăng ký doanh nghiệp
		 </ul>`,
      `<p class='info'>Sau khi đăng ký tài khoản, nhân viên thuocsi.vn sẽ liên hệ trong vòng 24 giờ để xác nhận thông tin đã cung cấp và kích hoạt tài khoản.</p>`,
      `<p class='info'>Đăng nhập và bắt đầu tra cứu sản phẩm, tìm kiếm theo tên thuốc, hoạt chất, nhà bán hàng.
		Khám phá khuyến mãi độc quyền, mã giảm giá hàng tuần, tổng hợp sản phẩm bán chạy trên thuocsi.vn, chương trình ưu đãi từ các hãng dược yêu thích.</p>`,
      `<p class='info'>Đặt hàng ngay và nhận ưu đãi chiết khấu 10% cho đơn hàng đầu tiên, giao hàng miễn phí trên toàn quốc.</p>`,
    ],
  },
  {
    id: 'B',
    steps: ['Xác nhận đơn hàng', 'Xử lý đơn hàng', 'Vận chuyển đơn hàng', 'Nhận hàng và thanh toán'],
    images: [
      `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/5_New.jpg`,
      `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/6_New.jpg`,
      `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/7_New.jpg`,
      `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/8_New.jpg`,
    ],
    contents: [
      `<p class='info'>Sau khi đặt hàng thành công, thông báo sẽ được gửi đến tài khoản Zalo đặt hàng</p>`,
      `<h4 class='title'>Sau khi đặt hàng thành công :</h4>
		<ul>
		<li>Có thể chỉnh sửa đơn hàng trong 30 phút sau khi đặt đơn hàng thành công với điều kiện trong đơn không có sản phẩm khuyến mãi.</li>
		<li>Có thể mua lại đơn hàng đã đặt để tiết kiệm thời gian tìm kiếm.</li>
		<li>Có thể yêu cầu xuất hóa đơn giá trị gia tăng.</li>
		</ul>
	 `,
      `<p class='info'>Dễ dàng tra cứu vận đơn tại <a href='http://tracuu.thuocsi.vn'>http://tracuu.thuocsi.vn/</a> để biết được lộ trình di chuyển hàng hóa, đơn vị giao vận đồng thời biết được thời gian dự kiến giao hàng để có kế hoạch hàng hóa đảm bảo hoạt động kinh doanh hiệu quả.</p>`,
      `<h4 class='title'>Có thể sử dụng một trong các hình thức thanh toán sau: </h4>
		<ul>
		<li>Thanh toán tiền mặt khi nhận hàng.</li>
		<li>Chuyển khoản (Đối với hình thức chuyển khoản trước được giảm 0.5% giá trị đơn hàng).</li>
		</ul>
		<h4 class='title'>Thời gian khiếu nại đơn hàng: </h4>
		<ul>
		<li>thuocsi.vn chỉ tiếp nhận khiếu nại về đơn hàng trong thời gian 5 ngày kể từ lúc đơn hàng được cập nhật trạng thái đã giao hàng thành công.</li>
		</ul>
		`,
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  blockStep: {
    background: 'linear-gradient(171.58deg, rgba(210, 243, 223, 0.1) 0%, rgba(21, 169, 89, 0.1) 29.1%)',
    padding: '20px 0',
    position: 'relative',
    zIndex: 2,
  },
  blockContainer: {
    maxWidth: '1304px',
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    '@media (max-width:1280px)': {
      padding: '20px 40px',
    },
  },
  completedStep: {
    color: theme.palette.grey[900],
  },
  arrowIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '52px',
    height: '52px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);',
    borderRadius: '40px',
    zIndex: 1,
    '& .MuiIconButton-label ': {
      color: '#08AC60',
      '& svg': {
        width: '40px',
        height: '40px',
      },
    },
  },
  stepperContainer: {
    position: 'relative',
    '& .MuiIconButton-root:hover': {
      backgroundColor: '#08AC60',
      '& .MuiIconButton-label ': {
        color: '#FFFFFF',
      },
    },
    '& .MuiIconButton-root.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.38)',
      '& .MuiIconButton-label ': {
        color: '#FFFFFF',
      },
    },
    '& .MuiStepper-root': {
      height: '100%',
      borderRadius: '12px 0px 0px 12px',
    },
  },
  stepIcon: {
    '& .MuiStepIcon-root': {
      width: '60px',
      height: '60px',
    },
  },
  stepConnector: {
    '& .MuiStepConnector-vertical': {
      marginLeft: '30px',
      padding: '8px 0',
    },
    '& .MuiStepConnector-lineVertical': {
      minHeight: '80px',
    },
  },
  stepName: {
    '& .MuiStepLabel-label': {
      color: '#797979',
      fontFamily: 'ggsm',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'left',
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: '#09884D',
      fontFamily: 'ggsm',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'left',
    },
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: `${100 / aspectRatio}%`,
    pointerEvents: 'none',
  },
  imageCaption: {
    position: 'absolute',
    width: '698px',
    height: 'auto',
    left: '44px',
    bottom: '44px',
    background: 'rgba(255, 255, 255, 0.9)',
    zIndex: 1,
    padding: '16px 24px',
    borderRadius: '8px',
    backdropFilter: 'blur(2px)',
    '@media (max-width:1280px)': {
      bottom: '60px',
    },
  },
  imageCaptionActive: {
    animation: '$slide-up 1s',
    animationFillMode: 'both',
  },
  '@keyframes slide-up': {
    '0%': {
      transform: 'translateY(100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '60px',
    overflow: 'hidden',
    width: '889px',
    border: '1px solid #E9E9E9',
    background: '#FFFFFF',
  },
  button: {
    flex: '1',
    background: '#FFFFFF',
    transition: 'background-color 0.5s ease-in-out',
    '&.MuiButtonBase-root.MuiButton-contained': {
      '&:hover': {
        backgroundColor: '#EDFDF6',
      },
      '& .MuiTouchRipple-root': {
        backgroundColor: '#EDFDF6',
      },
    },
    '& .MuiButton-label': {
      zIndex: 1,
      fontFamily: 'ggsm',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '36px',
      textTransform: 'initial',
    },
  },
  active: {
    background: '#EDFDF6',
    borderRadius: '60px ',
    color: '#09884D',
    border: '1px solid',
  },
  borderRadiusLeft: {
    borderLeft: 'none',
  },
  borderRadiusRight: {
    borderRadius: '60px 0px 0px 60px',
    borderRight: 'none',
  },
  flex: {
    flex: '2',
  },
  stepperImage: {
    backgroundColor: '#FFFFFF',
    position: 'relative',
    minHeight: '600px',
    padding: '20px',
    borderRadius: '0px 12px 12px 0px',
  },
  context: {
    fontStyle: 'normal',
    lineHeight: 'normal',
    '& .title': {
      color: '#000',
      fontFamily: 'ggsm',
      fontWeight: 500,
      fontSize: '20px',
      margin: '4px 0',
    },
    '& .info': {
      color: '#000',
      fontFamily: 'ggsr',
      fontWeight: 400,
      fontSize: '18px',
      margin: '4px 0',
    },
    '& .decimalList': {
      listStyle: 'decimal',
      margin: '4px 0',
    },
  },
  btn: {
    display: 'flex',
    height: '40px',
    padding: '12px 40px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    borderRadius: '30px',
    background: 'var(--gradient-green, linear-gradient(270deg, #08AC60 18.75%, #26BF78 84.38%))',
    color: '#fff',
    textTransform: 'uppercase',
  },
}));

export function CustomStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentStepOption, setCurrentStepOption] = useState(stepOptions[0]);
  const [selectedImage, setSelectedImage] = useState(currentStepOption.images[0]);
  const { toggleSignUp } = useAuth();
  useEffect(() => {
    setSelectedImage(currentStepOption.images[activeStep]);
  }, [activeStep, currentStepOption, activeIndex]);

  const handleStepOptionChange = (option) => () => {
    setCurrentStepOption(option);
    setActiveStep(0);
    setActiveIndex(0);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    setActiveIndex(step);
  };

  const handleNextStep = () => {
    if (activeStep < currentStepOption.steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setActiveIndex((prevActiveIndex) => prevActiveIndex + 1);
    }
  };

  const handlePreviousStep = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setActiveIndex((prevActiveIndex) => prevActiveIndex - 1);
    }
  };

  const stepIconProps = (index) => ({
    classes: {
      text: activeStep === index ? classes.completedStep : '',
    },
    style: {
      width: '60px',
      height: '60px',
    },
  });
  const [isVisible, setIsVisible] = useState(false);

  const imageRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    });

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);
  return (
    <div className={classes.blockStep}>
      <Container className={classes.blockContainer}>
        <div className={classes.buttonContainer}>
          <Button
            className={`${classes.button} ${currentStepOption.id === 'A' ? classes.active : ''} ${
              currentStepOption.id !== 'A' ? classes.borderRadiusRight : ''
            } ${classes.flex}`}
            onClick={handleStepOptionChange(stepOptions[0])}
          >
            4 Bước trở thành khách hàng thuocsi.vn
          </Button>
          <Button
            className={`${classes.button} ${currentStepOption.id === 'B' ? classes.active : ''} ${
              currentStepOption.id !== 'B' ? classes.borderRadiusLeft : ''
            }`}
            onClick={handleStepOptionChange(stepOptions[1])}
          >
            4 Bước xử lý đơn hàng
          </Button>
        </div>
        <Grid container className={classes.stepperContainer}>
          <IconButton onClick={handlePreviousStep} disabled={activeStep === 0} className={classes.arrowIcon} style={{ left: '-24px' }}>
            <NavigateBeforeIcon />
          </IconButton>
          <Grid item xs={12} sm={3}>
            <Stepper activeStep={activeStep} nonLinear orientation="vertical" className={classes.stepConnector}>
              {currentStepOption.steps.map((label, index) => (
                <Step key={label} className={classes.stepIcon}>
                  <StepButton onClick={handleStep(index)} className={classes.stepName}>
                    <StepLabel StepIconProps={stepIconProps(index)}>{label}</StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} sm={9} className={classes.stepperImage}>
            <div
              className={clsx(classes.imageCaption, activeStep === activeIndex ? classes.imageCaptionActive : '')}
              key={`step-${activeStep}-${activeIndex}`}
            >
              <div className={classes.context}>
                <div dangerouslySetInnerHTML={{ __html: currentStepOption.contents[activeStep] }} />
                {currentStepOption.id !== 'B' || (currentStepOption.id === 'B' && activeStep === stepOptions[1].steps.length - 1) ? (
                  <Button className={classes.btn} onClick={toggleSignUp}>
                    Đăng Ký Ngay
                  </Button>
                ) : null}
              </div>
            </div>
            <div className={classes.imageContainer} ref={imageRef}>
              <ImageFallbackBanner src={selectedImage} alt="step" layout="fill" objectFit="cover" width={2100} quality={100} />
            </div>
          </Grid>
          <IconButton
            onClick={handleNextStep}
            disabled={activeStep === currentStepOption.steps.length - 1}
            className={classes.arrowIcon}
            style={{ right: '-24px' }}
          >
            <NavigateNextIcon />
          </IconButton>
        </Grid>
      </Container>
    </div>
  );
}
