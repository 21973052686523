import { FRONTEND_APIS, MARKETING_API } from 'constants/APIUriV2';
import { ENUMS_MINI_BANNER_IMAGE_LAYOUT } from 'constants/Enums/mini-banner';
import { GET } from './Clients';

const getListBanner = (ctx) => {
  const url = FRONTEND_APIS.BANNER;
  return GET({ url, ctx, isBasic: true, isAuth: false });
};
const getBannerNano = (ctx, params) => {
  const url = FRONTEND_APIS.BANNER;
  return GET({ url, ctx, isBasic: true, isAuth: false, params });
};
const getCampaignList = (ctx, params) => {
  const url = FRONTEND_APIS.CAMPAIGNS;
  return GET({ url, ctx, isBasic: true, isAuth: false, params });
};

const getHashtagTopSearch = () => GET({ url: '/web/top-search', mock: true, isAuth: false });

const getProductFiles = ({ q = {}, isMienBac = false }) =>
  GET({ url: '/web/sanphammoi', params: { q: JSON.stringify(q), isMienBac }, mock: true, isAuth: false });

const getListBannerMock = () => {
  const url = '/web/banner';
  return GET({ url, mock: true, isAuth: false });
};

const getMostSearchMock = () => GET({ url: '/web/most-search', mock: true, isAuth: false });

const getProductPriceVolatility = ({ q = {}, regionCode, offset = 0, limit = 1000, getTotal }) =>
  GET({ url: FRONTEND_APIS.PRICE_VOLATILITY, params: { q: JSON.stringify(q), offset, limit, regionCode, getTotal } });

const getCustomerMiniBannerList = (ctx, q = {}, offset = 0, limit = 300, isBasic = false) =>
  GET({ url: FRONTEND_APIS.CUSTOMER_MINI_BANNER_IMAGE, ctx, params: { q: JSON.stringify(q), offset, limit }, isBasic });

const getClientCustomerMiniBannerList = (q = {}, offset = 0, limit = 300, isBasic = false) =>
  GET({ url: FRONTEND_APIS.CUSTOMER_MINI_BANNER_IMAGE, params: { q: JSON.stringify(q), offset, limit }, isBasic });

export const getCampaignListV2 = (params) => {
  const url = FRONTEND_APIS.CAMPAIGNS;
  return GET({ url, params });
};

export default {
  getListBanner,
  getBannerNano,
  getCampaignList,
  getListBannerMock,
  getMostSearchMock,
  getHashtagTopSearch,
  getProductFiles,
  getProductPriceVolatility,
  getCustomerMiniBannerList,
  getClientCustomerMiniBannerList,
};
