import { CUSTOMER_API } from 'constants/APIUriV2';
import { PUT } from './Clients';

const ratingOrder = async ({ body }) => {
  try {
    const response = await PUT({ url: CUSTOMER_API.RATING_ORDER, body });
    return response;
  } catch (error) {
    return error?.message;
  }
};

export default {
  ratingOrder,
};
