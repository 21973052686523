import React, { useEffect, useState } from 'react';
import { useAuth } from 'context/Auth';
import { API_CHAT_DOMAIN } from 'sysconfig';
import { GroupChat } from '@thuocsi/customer-live-chat';
import { getSessionTokenClient } from './SessionUtils';

const LiveChat = ({ liveStreamID }) => {
  const protocolWS = 'wss';
  const [accessToken, setAccessToken] = useState(null);
  const { auth, refreshKey } = useAuth();

  useEffect(() => {
    const sessionToken = getSessionTokenClient();
    if (!sessionToken) {
      setAccessToken(auth);
    } else {
      setAccessToken(sessionToken);
    }
  }, [auth]);

  return (
    <div>
      <GroupChat
        apiChatUrl="/backend"
        websocketChatUrl={`${protocolWS}://${API_CHAT_DOMAIN}/integration/chat/v1/web-socket`}
        isShowLog
        handleAppError={() => {}}
        accessToken={accessToken}
        key={refreshKey}
        liveStreamID={liveStreamID}
      />
    </div>
  );
};

export default LiveChat;
