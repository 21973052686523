import Container from '@material-ui/core/Container';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import clsx from 'clsx';
import { DEFAULT_THUOCSI_TITLE } from 'constants/data';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { STATIC_STORAGE } from 'sysconfig';
import { ImageFallbackBanner } from 'utils/ImageFallback';
import { CompanyIntroduction } from '../Company';
import styles from './styles.module.css';

const dataCustomer = [
  {
    id: 1,
    quote: 'Mua hàng ở thuocsi.vn đã hơn 3 năm rồi mà chưa từng có khiếu nại bao giờ, kèm nhân viên tư vấn rất ok.',
    imgSrc: '/img/new/KH1_NEW.jpg',
    author: 'Chị Huyền',
    location: 'Quầy thuốc Huyền Khôi - Đồng Nai',
  },

  {
    id: 2,
    quote:
      'Mới được biết về thuocsi.vn mấy tháng gần đây thôi, nhưng khi đặt hàng được nhân viên tư vấn kỹ, không có bất kì khiếu nại nào cả. Tôi hoàn toàn hài lòng về thuocsi.vn',
    imgSrc: '/img/new/KH2_NEW.jpg',
    author: 'Chị Thanh',
    location: 'Quầy thuốc An Tâm - Quảng Nam',
  },

  {
    id: 3,
    quote:
      'Cô mua hàng trên thuocsi.vn được hơn một năm rưỡi, cô rất hài lòng về phương thức đặt hàng, quy trình tự động để tham gia các chương trình trả thưởng từ thuocsi.vn',
    imgSrc: '/img/new/KH3_NEW.jpg',
    author: 'Cô Ngọc Loan',
    location: 'Nhà thuốc Ngọc Lan - Tây Ninh',
  },

  {
    id: 4,
    quote: 'Lúc đầu cũng ngại đặt hàng online nhưng dần dần thấy rất ổn, giao hàng nhanh, khi có phản hồi về đơn hàng cũng được xử lí nhanh.',
    imgSrc: '/img/new/KH4_NEW.jpg',
    author: 'Mỹ Sang',
    location: 'Nhà thuốc Mỹ Sang - Phú Yên',
  },

  {
    id: 5,
    quote: 'thuocsi.vn có đầy đủ mặt hàng đóng toa, giá ổn, nhân viên hỗ trợ nhiệt tình, sẽ ủng hộ lâu dài.',
    imgSrc: '/img/new/KH5_NEW.jpg',
    author: 'Chị Ngọc Nhì',
    location: 'Quầy thuốc Ngọc Nhì - Đồng Nai',
  },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
      },
    },
  ],
};
const CustomerSayAbout = ({ isLogin = false }) => (
  <div className={isLogin ? styles.backgroundContainer : undefined}>
    <Container className={styles.boxContainer}>
      <CompanyIntroduction titleText="Khách hàng nói gì về " branchName={DEFAULT_THUOCSI_TITLE} />
      <Slider {...settings} autoplay={3000}>
        {dataCustomer.map((item) => (
          <div key={item.id} className={styles.box}>
            <div className={styles['block-wrapper']}>
              <div className={styles.block}>
                <div className={styles['overlap-group']}>
                  <div className={styles.rectangle}>
                    <ImageFallbackBanner src={`${STATIC_STORAGE}${item?.imgSrc}`} layout="fill" alt={item.imgSrc} />
                  </div>
                  <div className={clsx(styles.frame, item.id % 2 === 0 ? styles.frameLight : null)}>
                    <div className={styles.div}>
                      <p className={clsx(styles.textWrapper, item.id % 2 === 0 && styles.textWrapperLight)}>
                        <FormatQuoteIcon className={clsx(styles.rotate, styles.quote)} />
                        {item.quote}
                        <FormatQuoteIcon className={styles.quote} />
                      </p>
                    </div>
                    <div className={styles['frame-3']}>
                      <div className={clsx(styles.textWrapper, item.id % 2 === 0 && styles.textWrapperLight)}>{item.author}</div>
                      <p className={clsx(styles.p, item.id % 2 === 0 && styles.pLight)}>{item.location}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </Container>
  </div>
);

export default CustomerSayAbout;
