import { PRODUCT_API } from 'constants/APIUriV2';
import { GET, GET_ALL, POST, PUT } from './Clients';

export const getWishlistBySkus = async ({ ctx, skus = null }) => {
  const body = {
    skus,
  };
  return POST({
    url: PRODUCT_API.WISHLIST_LIST,
    body,
    ctx,
  });
};

export const getWishlist = async ({ ctx, sku = null, customerID, offset, limit }) => {
  const params = {
    q: JSON.stringify({ sku, customerID }),
    getTotal: true,
    offset,
    limit,
  };
  return GET({
    url: PRODUCT_API.WISHLIST,
    params,
    ctx,
  });
};

const getAllWishlist = async ({ customerID }) => {
  const params = { q: JSON.stringify({ customerID }) };
  return GET_ALL({
    url: PRODUCT_API.WISHLIST_LIST,
    params,
  });
};

export async function callMultiRequest({ payloads, callback, limit, limitPerRequest = 1 }) {
  const returnVariable = {
    errors: [],
    data: [],
  };
  // limit: number of payloads =)))

  for (let i = 0; i < payloads.length; i += limit * limitPerRequest) {
    const sliceDatas = payloads.slice(i, i + limit * limitPerRequest);
    const groupDatas = sliceDatas.reduce((result, current) => {
      // 2-D array, element: array of payload element
      // result[i] is array with length <= limitPerRequest
      if (result[result.length - 1]?.length < limitPerRequest) {
        result[result.length - 1] ? result[result.length - 1].push(current) : (result[result.length - 1] = [current]);
      } else {
        result[result.length] = [current];
      }

      return result;
    }, []);

    await Promise.all(
      groupDatas?.map(async (payload) => {
        try {
          await callback(payload, returnVariable);
        } catch (err) {
          console.error(err);
        }
      }),
    );
  }
  return returnVariable;
}

const updateWishlist = async (sku) => POST({ url: PRODUCT_API.WISHLIST, body: { sku } });
const deleteItemWishlist = async (sku) => PUT({ url: PRODUCT_API.WISHLIST, body: { sku } });
const getListSkuByProductId = async ({ ctx, productIds }) => GET({ url: PRODUCT_API.SKUS_BY_ID, params: { productIds }, ctx });
export default { getWishlistBySkus, updateWishlist, deleteItemWishlist, getListSkuByProductId, getWishlist, getAllWishlist };
