import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { ENUMS_MINI_BANNER_IMAGE_LAYOUT } from 'constants/Enums/mini-banner';
import { settingsSliderBanner } from 'constants/data';
import useOnScreen from 'hooks/useOnScreen';
import { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ImageFallbackBanner } from 'utils/ImageFallback';
import { debounceFunc300 } from 'utils/debounce';
import gtag from 'utils/gtag';
import { useStore } from 'zustand-lib/storeGlobal';
import styles from './styles.module.css';

function MiniBannerBlock({ isMobile }) {
  const miniBanners = useStore((state) => state.miniBanners);
  const isBannerSize4 = miniBanners?.length === ENUMS_MINI_BANNER_IMAGE_LAYOUT.MINI_BANNERS_SIZE_4.quantity;

  const bannerRefs = useRef([]);
  const handleBannerView = (index, mini, isMobile) => {
    gtag.showMiniBanner({ link: miniBanners[index], total: mini.length, isMobile });
  };
  const wrapperRef = useRef(null);
  const visible = useOnScreen(wrapperRef);
  const getVisibleElements = (eventRefs) => {
    const elements = eventRefs.current;
    const visible = [];
    elements?.forEach((element, index) => {
      const rect = element?.getBoundingClientRect();
      const isVisible = rect.left >= 0 && rect.right <= window.innerWidth;
      if (isVisible) {
        visible.push(index);
      }
    });
    return visible;
  };
  const executeAfterDebounce = (visibleElements) => {
    visibleElements.forEach((element) => {
      gtag.showMiniBanner({ link: miniBanners[element], total: miniBanners.length, isMobile });
    });
  };

  useEffect(() => {
    if (visible) {
      const visibleElements = getVisibleElements(bannerRefs);
      debounceFunc300(() => {
        executeAfterDebounce(visibleElements);
      });
    }
  }, [visible]);

  if (!miniBanners?.length) {
    return <></>;
  }

  if (isMobile) {
    const Block1 = () => (
      <div className={styles.mobileBlock1}>
        {miniBanners.slice(0, 2).map((banner, index) => (
          <Box
            key={banner.imageURL}
            className={clsx(styles[`miniBannerMobileBlock_${index + 1}`], isBannerSize4 && styles.isBannerSize4)}
            onClick={() => {
              gtag.clickMiniBanner({ link: banner, total: miniBanners.length });
            }}
          >
            <a href={banner.url || '/'} target="_blank" rel="noopener noreferrer">
              <ImageFallbackBanner src={banner.imageURL} layout="fill" width={1200} objectFit="cover" />
            </a>
          </Box>
        ))}
      </div>
    );

    const Block2 = () => (
      <div className={styles.mobileBlock2}>
        {miniBanners.slice(2).map((banner, index) => (
          <Box
            key={banner.imageURL}
            className={clsx(styles[`miniBannerMobileBlock_${index + 3}`], isBannerSize4 && styles.isBannerSize4)}
            onClick={() => {
              gtag.clickMiniBanner({ link: banner, total: miniBanners.length, isMobile: true });
            }}
          >
            <a href={banner.url || '/'} target="_blank" rel="noopener noreferrer">
              <ImageFallbackBanner src={banner.imageURL} layout="fill" width={1200} objectFit="cover" />
            </a>
          </Box>
        ))}
      </div>
    );

    return (
      <div>
        <Slider
          {...settingsSliderBanner}
          autoplaySpeed={4000}
          afterChange={(index) => {
            if (index === 0) {
              miniBanners.slice(0, 2).forEach((banner, id) => {
                handleBannerView(id, miniBanners);
              });
            } else if (index === 1) {
              miniBanners.slice(2).forEach((banner, id) => {
                handleBannerView(id + 2, miniBanners, isMobile);
              });
            }
          }}
        >
          <Block1 />
          <Block2 />
        </Slider>
      </div>
    );
  }
  return (
    <div className={styles.miniBannerBlockWrapper} ref={wrapperRef}>
      <div className={styles.gridImagesWrapper}>
        <div className={styles.gridImages}>
          {miniBanners.map((banner, index) => (
            <Box
              key={banner.imageURL}
              ref={(el) => {
                bannerRefs.current[index] = el;
              }}
              onClick={() => {
                gtag.clickMiniBanner({ link: banner, total: miniBanners.length });
              }}
              className={styles[`${isBannerSize4 ? 'miniBannerImageSize4' : 'miniBannerImageSize5'}_${index + 1}`]}
            >
              <a href={banner?.url || '/'} target="_blank" rel="noopener noreferrer">
                {banner?.imageURL && <ImageFallbackBanner src={banner.imageURL} layout="fill" objectFit="cover" width={1500} />}
              </a>
            </Box>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MiniBannerBlock;

