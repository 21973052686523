import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import { useState } from 'react';
import { ImageFallbackBanner } from 'utils/ImageFallback';
import styles from './styles.module.css';

export const containerContent = [
  {
    id: 0,
    title: 'Mạng lưới',
    text: 'Công ty TNHH Buymed được thành lập vào năm 2017, là một start-up hướng đến mục tiêu cách mạng hóa ngành chăm sóc sức khỏe trên toàn Châu Á.',
    iviText:
      'Buymed bắt đầu sứ mệnh của mình từ một văn phòng nhỏ tại Singapore và chỉ sau một thời gian ngắn, chúng tôi đã mở rộng thành một tổ chức đa quốc gia trong khu vực Đông Nam Á với quy mô hơn 500 nhân viên.',
    iviPartner:
      'Chúng tôi phát triển mạng lưới phân phối dược phẩm, sản phẩm chăm sóc sức khỏe và trang thiết bị vật tư y tế với nguồn cung 100% đến từ các nhà sản xuất uy tín trên toàn bộ Việt Nam và Đông Nam Á.',
    image: `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/GT1_NEW.jpg`,
  },
  {
    id: 1,
    title: 'Tầm Nhìn',
    text: 'Tầm nhìn của chúng tôi: Trở thành nền tảng công nghệ y tế lớn nhất Đông Nam Á, tiên phong trong ứng dụng và phát triển công nghệ vào hệ thống y tế vì sức khỏe cộng đồng.',
    iviText:
      'Việc tiếp cận toàn cầu với dịch vụ chăm sóc sức khỏe chất lượng cao và chi phí hiệu quả là bước đệm đầu tiên hướng tới một tương lai phát triển hệ thống Y Tế tốt đẹp và bền vững.',
    iviPartner:
      'Chúng tôi tin vào tiềm năng của chuyển đổi kỹ thuật số đối với xã hội, điều này có thể được thực hiện hóa bằng cách kết hợp công nghệ vào chăm sóc sức khỏe.',

    image: `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/GT3_NEW.jpg`,
  },
  {
    id: 2,
    title: 'Sứ Mệnh',
    text: 'Sứ mệnh của chúng tôi: Ứng dụng mô hình hiện đại nhất để giải quyết vấn đề y tế một cách nhanh chóng và chất lượng.',
    iviText:
      'Đối với Quý khách hàng: Sử dụng công nghệ cung cấp các giải pháp đặt hàng dược phẩm và được giải quyết vấn đề nhanh chóng, chất lượng với các cam kết về nguồn gốc sản phẩm và hiệu quả chi phí.',
    iviPartner:
      'Đối với các đối tác: Cung cấp giải pháp nâng cao nhận diện thương hiệu và cơ hội mở rộng thị trường, giảm thiểu chi phí kho bãi, vận chuyển.',
    image: `${process.env.NEXT_PUBLIC_STATIC_STORAGE || ''}/img/new/GT2_NEW.jpg`,
  },
];

export const CompanyIntroduction = ({ titleText, branchName = '', noLine = false }) => (
  <div className={styles.companyIntroduction}>
    <div className={styles.title}>
      <div className={clsx(styles.companyTitle, noLine && styles.companyIntroductionForU)}>
        {titleText} <span className={styles.branchName}>{branchName}</span>
      </div>
      {!noLine && (
        <svg className={styles.vector} width="200" viewBox="0 0 200 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 2H200" stroke="#09884D" strokeWidth="4" strokeLinecap="round" />
        </svg>
      )}
    </div>
  </div>
);

function CompanyIntroduce() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (id) => {
    setActiveTab(id);
  };
  const handlePreviousTab = () => {
    setActiveTab((prevTab) => prevTab - 1);
  };

  const handleNextTab = () => {
    setActiveTab((prevTab) => prevTab + 1);
  };
  return (
    <div className={styles.bg}>
      <Container className={styles.boxContainer}>
        <CompanyIntroduction titleText="Giới thiệu về công ty" />
        <div className={styles['company-introduce']}>
          <IconButton
            style={{ left: '-16px' }}
            onClick={handlePreviousTab}
            disabled={activeTab === 0}
            className={clsx(styles.boxBtn, activeTab === 0 && styles.disabled)}
          >
            <NavigateBeforeIcon style={{ color: '#08ac60', cursor: 'pointer' }} className={styles.iconButton} />
          </IconButton>
          <Grid container spacing={2}>
            <Grid item xs={12} container direction={activeTab % 2 === 1 ? 'row-reverse' : 'row'} className={styles.container}>
              <Grid item xs={12} sm={6}>
                <div className={styles.text}>
                  <div className={styles.content}>{containerContent[activeTab].text}</div>
                  <div className={styles['ivi-text']}>{containerContent[activeTab].iviText}</div>
                  <div className={styles['ivi-partner']}>{containerContent[activeTab].iviPartner}</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={styles.gridImagesWrapper}>
                  <div className={styles.gridImages}>
                    <ImageFallbackBanner
                      src={containerContent[activeTab].image}
                      alt={containerContent[activeTab].text}
                      layout="fill"
                      loading="lazy"
                      width={1200}
                      quality={100}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} container justifyContent="flex-start">
              <div className={styles['group-button']}>
                <div className={styles['tabs-slider']}>
                  {containerContent.map((content) => (
                    <Button
                      key={content.id}
                      className={clsx(styles['tab-item'], { [styles.active]: activeTab === content.id })}
                      onClick={() => handleTabClick(content.id)}
                    >
                      <div className={clsx(styles.title, { [styles.active]: activeTab === content.id })}>{content.title}</div>
                    </Button>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
          <IconButton
            className={clsx(styles.boxBtn, activeTab === containerContent.length - 1 ? styles.disabled : null)}
            style={{ right: '-16px' }}
            onClick={handleNextTab}
            disabled={activeTab === containerContent.length - 1}
          >
            <NavigateNextIcon style={{ color: '#08ac60', cursor: 'pointer' }} className={styles.iconButton} />
          </IconButton>
        </div>
      </Container>
    </div>
  );
}

export default CompanyIntroduce;
