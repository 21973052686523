import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { getData } from 'clients/Clients';
import WebServiceClient from 'clients/WebServiceClient';
import LinkComp from 'components/atoms/LinkComp';
import { useAuth } from 'context/Auth';
import { useEffect, useState } from 'react';
import { ImageFallbackProductImage, ImageFallbackStoreImage } from 'utils/ImageFallback';
import { getLinkProxy, getProxyImageList } from 'utils/ImageUtils';
import { CompanyIntroduction } from '../Company';
import styles from './styles.module.css';

const SellerTop = () => {
  const [sellerResult, setSellerResult] = useState(null);
  const { toggleLogin } = useAuth();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await WebServiceClient.sellerSkipLogin();
        setSellerResult(getData(response));
      } catch (error) {
        console.error('Error fetching API:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container className={styles.boxContainer}>
      <CompanyIntroduction titleText="Nhà bán hàng nổi bật" />
      <Grid container spacing={4}>
        {sellerResult?.map(({ seller, productDatas }) => {
          const { isStore, sellerStore } = seller;
          const { logo, name, numberProductDisplay, description } = sellerStore;
          const displayProducts = productDatas?.slice(0, 3) || [];

          return (
            <Grid key={sellerStore.code} item xs={12} md={6}>
              <div className={styles.store}>
                <div className={styles.content}>
                  <div className={styles.title}>
                    <div className={styles.profile}>
                      <div className={styles.avatar}>
                        <ImageFallbackStoreImage
                          className={styles.avatarChild}
                          src={logo ? getProxyImageList(logo, 400)[0] : ''}
                          layout="fill"
                          fallbackSrc={getLinkProxy(logo[0])}
                        />

                        {isStore && (
                          <div className={styles.tagCard}>
                            <div className={styles.medxStore}>thuocsiStore</div>
                          </div>
                        )}
                      </div>
                      <div className={styles.content1}>
                        <div className={styles.medxStoreName}>{name}</div>
                        <Typography className={styles.snPhm}>{numberProductDisplay} sản phẩm</Typography>
                      </div>
                    </div>
                  </div>
                  <div className={styles.loremIpsumDolor1} dangerouslySetInnerHTML={{ __html: description }} />
                  <div className={styles.topProductWrapper}>
                    <div className={styles.top_product}>
                      {displayProducts?.map(({ product, sku }) => {
                        const { imageUrls } = product;

                        return (
                          <LinkComp href={`/product/${sku?.slug}`} style={{ width: '100%', padding: 0 }}>
                            <Box className={styles.productMinimize}>
                              <div className={styles.productImage}>
                                <ImageFallbackProductImage src={imageUrls.length > 0 && getProxyImageList(imageUrls)[0]} layout="fill" alt="" />
                              </div>
                              <Typography className={styles.productName}>{product?.name}</Typography>
                            </Box>
                          </LinkComp>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <div className={styles.bottomInfo}>
            <Typography>Đăng nhập để xem thêm 35.000 sản phẩm của hơn 2000 nhà bán hàng trên thuocsi.vn</Typography>
            <Button onClick={toggleLogin}>Khám phá ngay</Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SellerTop;
