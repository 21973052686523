import { Fab, IconButton, Tooltip } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { isValid, isValidWithoutData } from 'clients/Clients';
import WishlistClient from 'clients/WishlistClient';
import { HTTP_STATUS } from 'constants/Enums/http';
import { WISHLIST_IMAGE_ACTIVE, WISHLIST_IMAGE_INACTIVE } from 'constants/Icons';
import { useEffect, useState } from 'react';
import NotifyUtils from 'utils/NotifyUtils';
import styles from './styles.module.css';

const FavoriteButton = ({ product, isProductCard = false }) => {
  const [isBookmarked, setIsFavorite] = useState(false);



  useEffect(() => {
    setIsFavorite(() => product?.isWishList);
  }, []);


  const handleDeleteFavorite = async (product) => {
    const res = await WishlistClient.deleteItemWishlist(product?.sku);
    if (isValidWithoutData(res)) {
      setIsFavorite(() => false);
      return NotifyUtils.success(`Đã xóa ${product?.name} khỏi danh sách quan tâm`);
    }
    return NotifyUtils.error(`Xóa sản phẩm ${product?.name} khỏi danh sách quan tâm thất bại`);
  };

  const handleUpdateFavorite = async (product) => {
    const res = await WishlistClient.updateWishlist(product?.sku);
    if (isValid(res) || res?.status === HTTP_STATUS.Existed) {
      setIsFavorite(() => true);
      return NotifyUtils.success(`Đã thêm ${product?.name} vào danh sách quan tâm`);
    }
    return NotifyUtils.error(`Thêm ${product?.name} vào danh sách sản phẩm quan tâm thất bại`);
  };

  if (isProductCard) {
    return (
      <Tooltip title={!isBookmarked ? 'Đánh dấu sản phẩm quan tâm' : 'Bỏ đánh dấu sản phẩm'}>
        {isBookmarked ? (
          <IconButton
            className={styles.favorite_icon_wrapper}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleDeleteFavorite(product);
            }}
          >
            <WISHLIST_IMAGE_ACTIVE className={styles.favorite_icon} />
          </IconButton>
        ) : (
          <IconButton
            className={styles.favorite_icon_wrapper}
            onClick={(e) => {
              e.stopPropagation();
              handleUpdateFavorite(product);
            }}
          >
            <WISHLIST_IMAGE_INACTIVE />
          </IconButton>
        )}
      </Tooltip>
    );
  }

  return (
    <Tooltip title={!isBookmarked ? 'Đánh dấu sản phẩm quan tâm' : 'Bỏ đánh dấu sản phẩm'}>
      <Fab
        className={styles.icon}
        size="small"
        aria-label="like"
        onClick={(e) => {
          e.stopPropagation();
          if (isBookmarked) {
            handleDeleteFavorite(product);
          } else {
            handleUpdateFavorite(product);
          }
        }}
      >
        {isBookmarked ? (
          <FavoriteIcon style={{ fontSize: 24 }} className={styles.like} />
        ) : (
          <FavoriteBorderIcon style={{ fontSize: 24, color: '#AFAFAF' }} />
        )}
      </Fab>
    </Tooltip>
  );
};

export default FavoriteButton;
