import LinearProgress from '@material-ui/core/LinearProgress';
import { styled } from '@mui/material/styles';
import { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ width, height, bgColor, bgLiner, radius = 20, linerRadius = 20 }) => ({
  height,
  width,
  borderRadius: radius,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: bgColor,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: linerRadius,
    backgroundColor: bgLiner,
  },
}));
export default BorderLinearProgress;
