/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, ButtonBase, CircularProgress, ClickAwayListener, Modal, Typography } from '@material-ui/core';
import { PROMOCODES_POPUP_GUIDE_BANNER } from 'constants/Images';
import { formatFloatNumber } from 'utils/FormatNumber';
import { ImageFallbackStatic } from 'utils/ImageFallback';
// import { IconButton } from 'material-ui';
import styles from './styles.module.css';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  loyaltyRsPoint: number;
  isMoving: boolean;
  atLeastLoyaltyPoint: number;
}

const GuildContent = ({ point = 0, atLeastLoyaltyPoint = 0 }: { point: number, atLeastLoyaltyPoint: number }): JSX.Element => (
  <Box position="relative">
    <div className={styles.imageWrapper}>
      <ImageFallbackStatic src={PROMOCODES_POPUP_GUIDE_BANNER} alt="Thuocsi Logo New" objectFit="contain" width={263} height={160} layout="fixed" />
    </div>
    <Typography className={styles.content} variant="body2">
      Quý khách có <span style={{ color: '#08AC60', fontFamily: 'ggsm' }}>{formatFloatNumber(point)}</span> điểm tích lũy sẽ hết hạn vào 0h ngày 01/03/2024. {point >= atLeastLoyaltyPoint ? 'Hãy đổi điểm ngay để không bỏ lỡ những món quà từ thuocsi.vn' : ''}
    </Typography>
  </Box>
);

const PopupTourRSLoyaltyPoint = ({ open, handleClose, handleConfirm, loyaltyRsPoint, isMoving, atLeastLoyaltyPoint }: Props): JSX.Element => {
  return (
    <ClickAwayListener disableReactTree onClickAway={() => {}}>
      <Modal open={open} className={styles.containerModal}>
        <Box className={styles.containerTour}>
          <GuildContent point={loyaltyRsPoint} atLeastLoyaltyPoint={atLeastLoyaltyPoint} />
          <div className={styles.groupButton}>
            {loyaltyRsPoint >= atLeastLoyaltyPoint ? (
              <ButtonBase className={styles.understand} onClick={handleClose} disabled={isMoving}>
                Để sau
              </ButtonBase>
            ) : null}
            {loyaltyRsPoint >= atLeastLoyaltyPoint ? (
              <ButtonBase className={styles.try} onClick={handleConfirm} disabled={isMoving}>
                {isMoving ? <CircularProgress size={20} style={{ color: '#fff' }} /> : 'Đổi điểm'}
              </ButtonBase>
            ) : (
              <ButtonBase className={styles.try} onClick={handleClose} disabled={isMoving}>
                Xác nhận
              </ButtonBase>
            )}
          </div>
        </Box>
      </Modal>
    </ClickAwayListener>
  );
};

export default PopupTourRSLoyaltyPoint;
