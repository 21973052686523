import { useEffect, useMemo, useState } from 'react';
import calculateTimeLeftV2 from 'utils/calculateTimeLeftV2';
import styles from './styles.module.css';

const CountdownNew = ({ targetDate, hasMoreText = false }) => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const memoizedTimeRemaining = useMemo(() => calculateTimeLeftV2(targetDate), [timeRemaining]);

  useEffect(() => {
    setTimeRemaining((data) => data + 1);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((data) => data + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDate]);

  if (!targetDate) {
    return null;
  }

  const conditionRender = () => {
    if (+memoizedTimeRemaining?.hours) {
      return (
        <span className={styles.minWidth}>
          {memoizedTimeRemaining?.hours}h:<span className={styles.minWidth}>{memoizedTimeRemaining?.minutes}</span>m
        </span>
      );
    }
    if (+memoizedTimeRemaining?.minutes || +memoizedTimeRemaining?.seconds) {
      return (
        <span className={styles.minWidth}>
          {memoizedTimeRemaining?.minutes}m:<span className={styles.minWidth}>{memoizedTimeRemaining?.seconds}s</span>
        </span>
      );
    }
    return <></>;
  };

  return (
    <>
      {+memoizedTimeRemaining?.seconds || +memoizedTimeRemaining?.minutes || +memoizedTimeRemaining?.hours ? (
        <div>
          <span>{hasMoreText ? 'Còn ' : ''}</span>
          {conditionRender()}
        </div>
      ) : null}
    </>
  );
};

export default CountdownNew;
