import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ImageFallbackStatic } from 'utils/ImageFallback';

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <NavigateNextIcon />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <NavigateBeforeIcon />
    </div>
  );
}

function DailyCheckinNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ImageFallbackStatic src={`${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/dailyCheckin/arrow_right_icon.svg`} width={20} height={20} style={{ userSelect: 'none' }} />
    </div>
  );
}

function DailyCheckinPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ImageFallbackStatic src={`${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/dailyCheckin/arrow_left_icon.svg`} width={20} height={20} style={{ userSelect: 'none' }} />
    </div>
  );
}

export const settingsMultiImageBoxV2 = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};
export const settingsMobileMultiImageBoxV2 = {
  dots: false,
  infinite: false,
  slidesToShow: 4,
  swipeToSlide: true,
  rows: 1,
  speed: 500,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};
export const settingsNewProducts = {
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  rows: 1,
  arrows: true,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 3000,
  speed: 1000,
  lazyLoad: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 564,
      settings: {
        arrow: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
      },
    },
    {
      breakpoint: 640,
      settings: {
        arrow: false,
        variableWidth: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        centerMode: true,
        centerPadding: '15px',
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1140,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
  ],
};

export const settingsNewFlashSaleTimes = {
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  rows: 1,
  arrows: true,
  infinite: false,
  autoplay: false,
  lazyLoad: false,
  swipeToSlide: true,
  speed: 500,
  nextArrow: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" transform="matrix(-1 0 0 1 32 0)" fill="black" fillOpacity="0.2" />
      <path
        style={{ userSelect: 'none', pointerEvents: 'none' }}
        d="M21.5019 14.8991L14.7203 8.44037C14.4138 8.14679 14.0307 8 13.5709 8C13.1367 8 12.7663 8.14679 12.4598 8.44037C12.1533 8.73394 12 9.08868 12 9.50459C12 9.92049 12.1533 10.2752 12.4598 10.5688L18.1303 16L12.4598 21.3945C12.1533 21.6881 12 22.055 12 22.4954C12 22.9113 12.1533 23.2661 12.4598 23.5596C12.7663 23.8532 13.1367 24 13.5709 24C14.0307 24 14.4138 23.8532 14.7203 23.5596L21.5019 17.1009C21.834 16.8073 22 16.4404 22 16C22 15.5596 21.834 15.1927 21.5019 14.8991Z"
        fill="white"
      />
    </svg>
  ),
  prevArrow: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" transform="matrix(-1 0 0 1 32 0)" fill="black" fillOpacity="0.2" />
      <path
        style={{ userSelect: 'none', pointerEvents: 'none' }}
        d="M13.8697 15.9635L19.5402 10.5571C19.8467 10.2648 20 9.91172 20 9.49772C20 9.08371 19.8467 8.73059 19.5402 8.43835C19.2337 8.14612 18.8506 8 18.3908 8C17.9566 8 17.5862 8.14612 17.2797 8.43835L10.4981 14.8676C10.166 15.1598 10 15.5251 10 15.9635C10 16.4018 10.166 16.7671 10.4981 17.0594L17.2797 23.5616C17.5862 23.8539 17.9566 24 18.3908 24C18.8506 24 19.2337 23.8539 19.5402 23.5616C19.8467 23.2694 20 22.9163 20 22.5023C20 22.0883 19.8467 21.7352 19.5402 21.4429L13.8697 15.9635Z"
        fill="white"
      />
    </svg>
  ),

  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

export const settingsV1WebAppNewFlashSaleTimes = {
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  rows: 1,
  arrows: true,
  infinite: false,
  autoplay: false,
  speed: 1000,
  lazyLoad: false,
  focusOnSelect: false,
  focusOnChange: false,
  swipeToSlide: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

export const settingsPopupSharing = {
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  rows: 1,
  arrows: false,
  infinite: false,
  autoplay: false,
  speed: 1000,
  focusOnSelect: false,
  focusOnChange: false,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 564,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
};

export const createSettingsSliderBanner = (setCurrentIndex, setPercentTime, isMobile) => ({
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  rows: 1,
  swipeToSlide: true,
  arrows: !isMobile,
  nextArrow: !isMobile ? <SampleNextArrow /> : null,
  prevArrow: !isMobile ? <SamplePrevArrow /> : null,
  beforeChange: (_, next) => {
    setCurrentIndex(next);
    setPercentTime(0);
  },
});

export const settingDailyCheckin = {
  slidesToShow: 7,
  slidesToScroll: 7,
  rows: 1,
  arrows: true,
  infinite: false,
  autoplay: false,
  speed: 1000,
  swipe: false,
  draggable: false,
  nextArrow: <DailyCheckinNextArrow />,
  prevArrow: <DailyCheckinPrevArrow />,
};
