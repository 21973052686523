import ProductCardNew from 'components-v2/mocules/ProductCardNew';
import SliderProductBlock from 'components/mocules/Skeleton/SliderProductBlock';
import ListProductWithTitle from "components/mocules/ListProductWithTitle";

const MAXIMUM_PRODUCT_SHOW = 10

const ProductSliderSection = ({
  products = [],
  name = '',
  viewMore = true,
  redirect = '',
  loading = false,
  productsType = '',
  icon,
  isMobile = false,
}) => {
  return loading ? (
    <SliderProductBlock />
  ) : (
    <ListProductWithTitle name={name} viewMore={viewMore} redirect={redirect} loading={loading} productsType={productsType} icon={icon} isMobile={isMobile} gap={'15px'}>
      {products?.slice(0, MAXIMUM_PRODUCT_SHOW)?.map((product, index) => (
        <ProductCardNew product={product} key={`item-product-${product.slug}`} index={index} url={product?.defaultImage} />
      ))}
    </ListProductWithTitle>
  );
};

export default ProductSliderSection;
