import Modal from 'components/atoms/Modal';
import React from 'react';
import styles from './styles.module.css';

const ModalLayout = (props: any) => {
  const { width, onClose, visible, className, height, children } = props;
  const handleClose = (_: unknown, reason: string) => {
    const modalCloseKeys = ['backdropClick', 'escapeKeyDown'];
    if (!modalCloseKeys.includes(reason)) {
      onClose();
    }
  };
  return (
    <Modal className={className} open={!!visible} onClose={handleClose}>
      <div className={styles.auth_modal_content} style={{ width: `${width}`, height: `${height}` }}>
        <div className={styles.auth_modal_body}>{children}</div>
      </div>
    </Modal>
  );
};

export default ModalLayout;
