// ConfirmStep.tsx
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { OCR_REMIND } from 'constants/Icons';
import { useRouter } from 'next/router';
import ConfirmUI from '../Components/ConfirmUI';
import styles from '../index.module.css';
import { ConfirmText, ConfirmType } from '../utils';

export const ConfirmRemind = ({ onClose, onNext }: { onClose: () => void; onNext: (newStep: ConfirmType) => void }) => {
  const router = useRouter();
  const classes = clsx(styles.StepContainer, styles.confirmClose, styles.flexColCenter);

  const handleNextStep = async () => {
    // if (onNext) {
    //   onNext();
    // }
    router.push('/my-account?tab=3');
  };

  const onRedirectToHome = async () => {
    onClose();
  };

  return (
    <ConfirmUI classes={classes} buttonNext={ConfirmText.Close} buttonClose="Bỏ qua" onNext={handleNextStep} onClose={onRedirectToHome}>
      <div className={styles.title}>
        <OCR_REMIND />
        <Typography>Tài khoản chưa được kích hoạt</Typography>
      </div>
      <div className={styles.flexColCenter}>
        <Box className={styles.flexRow}>
          <Typography className={styles.content}>
            Bạn cần
            <Typography component="span" className={styles.strongContent}>
              &nbsp;xác thực giấy phép&nbsp;
            </Typography>
            để được kích hoạt tài khoản mua hàng tại thuocsi.vn
          </Typography>
        </Box>
      </div>
    </ConfirmUI>
  );
};
