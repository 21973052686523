
export enum ConfirmType {
  Selection = 'Selection',
  Close = 'Close',
  Unverified = 'Unverified',
  Success = 'Success',
  Preparation = 'Preparation',
  Licenses = 'Licenses',
  Gpp = 'Gpp',
}

export enum ConfirmText {
  Selection = 'Kích hoạt ngay',
  Close = 'Tiếp tục xác thực',
  Unverified = 'Xác thực ngay',
  Preparation = 'Xác thực ngay',
  Success = 'Đăng nhập ngay',
  Licenses = 'Tiếp tục',
  Gpp = 'Hoàn Tất',
  Remind = 'Xác thực ngay'
}

export enum valType {
  BusinessImages = 'businessImages',
  OrderFeedBack = 'orderFeedBack',
}

export enum LicenseErrors {
  others = `Đã có lỗi xảy ra trong quá trình ghi nhận Giấy phép. Quý khách vui lòng liên hệ<span> bộ phận CSKH </span> hoặc gọi hotline <span>1800 2038</span> để được hỗ trợ nhanh nhất`,
}

export type OcrFlowStepType = { onNext?: () => void; onClose: () => void; onPrevious?: () => void };

export enum OcrFlowStep {
  Confirm = 'Confirm',
  Licenses = 'Licenses',
}

export enum FormValuesEnum {
  date = 'approvedDate',
  customer = 'customerLicense',
  province = 'provinceCode',
  images = 'imageUrls',
  isFetching = 'isFetching',
  isSuccess = 'isSuccess',
}

export enum LicenseEnum {
  license = 'licenses',
  gpp = 'gpp',
  success = 'success',
}

export type ImageType = {
  url: string;
  fileType: string;
};

export type License = {
  approvedDate: string;
  customerLicense: string;
  provinceCode: string;
  imageUrls: ImageType[];
  isSuccess: boolean;
};

export type FormTypeValues = {
  [LicenseEnum.license]: License;
  [LicenseEnum.gpp]: License;
};

export const DEFAULT_FORM_VALUES = {
  [LicenseEnum.license]: {
    [FormValuesEnum.date]: '',
    [FormValuesEnum.customer]: '',
    [FormValuesEnum.province]: '',
    [FormValuesEnum.images]: [],
    [FormValuesEnum.isSuccess]: false,
  },
  [LicenseEnum.gpp]: {
    [FormValuesEnum.date]: '',
    [FormValuesEnum.customer]: '',
    [FormValuesEnum.province]: '',
    [FormValuesEnum.images]: [],
    [FormValuesEnum.isSuccess]: false,
  },
};
