import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import LinkComp from 'components/atoms/LinkComp';
import { BRAND_LOGO_SVG } from 'constants/Images';
import { PATH_NEWS } from 'constants/Paths';
import { DEFAULT_THUOCSI_LONG_TITLE } from 'constants/data';
import { useAuth } from 'context/Auth';
import { memo } from 'react';
import { DOMAIN_FEEDBACK, DOMAIN_SELLER_CENTER } from 'sysconfig';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import gtag from 'utils/gtag';
import CountDownBar from '../Header/CountDownBar';
import styles from './styles.module.css';

const Logo = memo(() => (
  <LinkComp href="/home" id="logo-thuocsi" title={DEFAULT_THUOCSI_LONG_TITLE}>
    <div className={styles.logo}>
      <ImageFallbackStatic
        href="/home"
        src={BRAND_LOGO_SVG}
        width="127px"
        height="22px"
        quality={100}
        layout="fixed"
        alt={DEFAULT_THUOCSI_LONG_TITLE}
        title={DEFAULT_THUOCSI_LONG_TITLE}
      />
    </div>
  </LinkComp>
));
const NavbarLeft = () => (
  <div className={styles.group_left}>
    <Logo />
  </div>
);
const NavbarCenter = () => (
  <div className={styles.group_center}>
    <div className={styles.headerTopLink}>
      <LinkComp name="Về chúng tôi" href="/about-us" target onClick={() => gtag.viewBlog()} className={styles.headerTopLink_content} />
    </div>
    <div className={styles.headerTopLink}>
      <LinkComp name="Hướng dẫn đặt hàng" href={DOMAIN_FEEDBACK} target onClick={() => gtag.viewBlog()} className={styles.headerTopLink_content} />
    </div>
    <div className={styles.headerTopLink}>
      <LinkComp name="Tin tức" href={PATH_NEWS} target onClick={() => gtag.viewBlog()} className={styles.headerTopLink_content} />
    </div>
    <div className={styles.headerTopLink}>
      <LinkComp
        name="Đăng ký bán hàng"
        href={DOMAIN_SELLER_CENTER}
        target
        onClick={() => gtag.viewSeller()}
        className={clsx(styles.headerTopLink_content, styles.iconTopSeller)}
      />
    </div>
  </div>
);

function NavbarV2() {
  const { toggleLogin, toggleSignUp } = useAuth();
  // useEffect(() => {
  //   gtag.displayTopSearch();
  // }, []);
  const NavbarRight = () => (
    <div className={styles.group_right}>
      <div className={styles.group_btn}>
        <ButtonBase className={styles.btn_register} type="button" onClick={toggleSignUp}>
          Đăng Ký
        </ButtonBase>
        <ButtonBase className={styles.btn_signUp} type="button" onClick={toggleLogin}>
          Đăng Nhập
        </ButtonBase>
      </div>
    </div>
  );
  return (
    <>
      <CountDownBar />
      <div className={clsx(styles.nav)}>
        <Container className={styles.wrap}>
          <div className={clsx(styles.WrapmenuFocus)}>
            <Grid container className={clsx(styles.menu)} alignContent="space-between" style={{ width: '100%', transition: 'width 1s' }}>
              <>
                <NavbarLeft />
                <NavbarCenter />
                <NavbarRight />
              </>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
}
export default memo(NavbarV2);
