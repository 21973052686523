import dynamic from 'next/dynamic';
import { useStore } from 'zustand-lib/storeGlobal';
import BannerSliderV2 from './BannerSliderV2';
import BrandedGoods from './BrandedGoods';
import CompanyIntroduce from './Company';
import CustomerSayAbout from './CustomerSayAbout';
import IntroCard from './IntroCard';
import News from './News';
import OfferForU from './OfferForU';
import Questions from './Questions';
import SellerTop from './SellerTop';
import { CustomStepper } from './Step';
import styles from './styles.module.css';

// const DynamicCustomStepper = dynamic(() => import('./Step'), { ssr: false });
const DynamicPartners = dynamic(() => import('./Partners'), {
  loading: () => <p>Loading...</p>,
  ssr: false,
});

const Home = ({ isMobile = false, bannerStatus = null, isAuthenticated = false, products = [], loading }) => {
  const miniBanners = useStore((state) => state.miniBanners);
  return (
    <div style={{ backgroundColor: '#ffffff' }}>
      <div className={styles.background}>
        <div className={styles.backgroundImage} />
        <div style={{ backgroundColor: 'rgba(21, 169, 89, 0.1)' }}>
          {bannerStatus && bannerStatus === 'ON' && <BannerSliderV2 />}
          <IntroCard />
        </div>
      </div>
      {miniBanners?.length > 0 && <BrandedGoods />}
      <CustomStepper />
      <SellerTop />
      <CompanyIntroduce />
      <DynamicPartners />
      <News />
      <Questions />
      <CustomerSayAbout />
      <OfferForU />
    </div>
  );
};
export default Home;
