import { memo } from 'react';

export const PlayIcon = memo(() => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M16.006 11.0818C16.5418 11.5767 16.5418 12.4233 16.006 12.9182L11.5982 16.99C10.7976 17.7295 9.5 17.1617 9.5 16.0718V7.9282C9.5 6.83829 10.7976 6.27045 11.5982 7.01001L16.006 11.0818Z"
      fill="#15A959"
    />
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#15A959" />
  </svg>
));
