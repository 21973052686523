import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import { useAuth } from 'context/Auth';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { CompanyIntroduction } from '../Company';
import styles from './styles.module.css';

const dataFooter = [
  {
    id: 1,
    image: `${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/iconCar.svg`,
    text: 'Giảm 10% & miễn phí vận chuyển cho đơn hàng đầu tiên.',
  },
  {
    id: 2,
    image: `${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/iconVoucher.svg`,
    text: 'Tham gia chương trình trả thưởng nhận ngay voucher.',
  },
  {
    id: 3,
    image: `${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/iconKey.svg`,
    text: 'Đăng ký ngay để nhận ưu đãi!',
  },
];

const OfferForU = () => {
  const { toggleSignUp } = useAuth();
  return (
    <div className={styles.wrapper}>
      <Container className={styles.boxContainer}>
        <CompanyIntroduction titleText="Nhận ngay ưu đãi dành cho bạn!" noLine />
        <div className={styles.box}>
          {dataFooter.map((item) => (
            <div key={item.id} className={styles.blockWrapper}>
              {item.id === 3 ? (
                <Button onClick={toggleSignUp} className={clsx(styles.block, item.id === 3 && styles.bgKey)}>
                  <ImageFallbackStatic layout="fixed" src={item.image} width="40" height="40" alt="ICON" />
                  <p className={styles.text}>{item.text}</p>
                </Button>
              ) : (
                <div className={clsx(styles.block, item.id === 3 && styles.bgKey)}>
                  <ImageFallbackStatic layout="fixed" src={item.image} width="40" height="40" alt="ICON" />
                  <p className={styles.text}>{item.text}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default OfferForU;
