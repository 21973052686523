import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { useAuth } from 'context/Auth';
import Image from 'next/image';
import gtag from 'utils/gtag';
import useSettingLocalStorage from 'zustand-lib/useSettingLocalStorage';
import styles from './styles.module.css';

export default function DownloadBar() {
  const { openDownload, setOpenDownload } = useSettingLocalStorage();

  const { isAuthenticated } = useAuth();

  const toggleOpenDownload = () => {
    setOpenDownload(!openDownload);
    if (openDownload && isAuthenticated) {
      let expiryTime;
      switch (process.env.NEXT_PUBLIC_ENV || 'stg') {
        case 'stg':
          expiryTime = 5000;
          break;
        case 'uat':
        case 'prd':
          expiryTime = 24 * 60 * 60 * 1000;
          break;
        default:
          expiryTime = 5000;
          break;
      }
      setOpenDownload('false', expiryTime);
    }
  };

  const handleClickClose = () => {
    toggleOpenDownload();
  };

  const handleClickDown = () => {
    toggleOpenDownload();
    gtag.downloadBanner();
    window.location.href = 'https://thuocsi.vn/app';
  };

  if (!openDownload) return <></>;

  return (
    <div className={styles.appBanner}>
      <div className={styles.frame}>
        <CloseIcon onClick={handleClickClose} htmlColor="#FFFFFF" />
        <div className={styles.iconText}>
          <Image src={`${process.env.NEXT_PUBLIC_CDN_PREFIX || ''}/images/LogoCircle_thuocsi.svg`} height={36} width={36} alt="Icon thuocsi" />
          <div className={styles.text}>
            <p>
              Tải ứng dụng <br /> Nhận ngay ưu đãi 50k!
            </p>
          </div>
        </div>
      </div>
      <Button onClick={handleClickDown} className={styles.button}>
        Tải xuống
      </Button>
    </div>
  );
}
