import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { PlayIcon } from 'assets/svg/PlayIcon';
import clsx from 'clsx';
import { ProductCardVertical } from 'components-v2/mocules/mobile/CardProduct';
import ProductPreview from 'components-v2/mocules/mobile/SliderMobile/ProductPreview';
import LinkComp from 'components/atoms/LinkComp';
import { DOUBLE_ARROW_ICON, SAME_INGREDIENT, SAME_STORE } from 'constants/Images';
import { MAP_BTN_TITLE_BY_REDIRECT_URL } from 'constants/data';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import gtag from 'utils/gtag';
import useMobileV2 from 'zustand-lib/storeMobile';
import useStartGuide from 'zustand-lib/useStartGuide';
import styles from './styles.module.css';
import {ListProductForPCWrapper} from "./ListProductForPCWrapper";

const MEGA_SALE = 'MEGA-SALE';


const ListProductWithTitle = ({
  children,
  name,
  redirect,
  viewMore,
  page = null,
  icon,
  iconWidth,
  iconHeight,
  isBestProducts = false,
  loading = false,
  type = '',
  products, // props using for mobile v2
  productsType,
  isMobile,
	gap
}) => {
  const { isGuide } = useStartGuide((state) => state);
  const ref = useRef(null);
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const TITLE_ICON = name === 'Sản phẩm cùng nhà bán hàng' ? SAME_STORE : SAME_INGREDIENT;
  const SlideItems = products?.map((item) => <ProductCardVertical {...item} />);
  const router = useRouter();
  const currentUrl = router.asPath;
  const wrapperRef = useRef(null);


  useEffect(() => {
    if (ref?.current) {
      if (isGuide) {
        ref.current.slickPause();
      } else {
        ref.current.slickPlay();
      }
    }
  }, [isGuide, ref]);

  return page === 'PRD_DETAIL' ? (
    <Paper
      ref={wrapperRef}
      className={clsx(styles.wrapper, isBestProducts && styles.bestProductsWrapper, {
        [styles.wrapper_mv2]: isMobileV2,
      })}
    >
      <Box
        className={clsx(styles.icon_name, isBestProducts && styles.bestProducts, {
          [styles.icon_name_mv2]: isMobileV2,
        })}
      >
        {icon && <ImageFallbackStatic src={icon} width={iconWidth || 40} height={iconHeight || 40} alt="icon" />}
        <Typography
          className={clsx(styles.name, isBestProducts && styles.bestProductsName, {
            [styles.name_mv2]: isMobileV2,
          })}
          variant="h5"
        >
          {isMobileV2 && <ImageFallbackStatic width={24} height={24} src={TITLE_ICON} />} {name}
        </Typography>
        {isBestProducts && (
          <Box className={styles.bestProducts_view_more}>
            <a href={`${redirect}`}>
              <span>Xem tất cả</span>
              <ImageFallbackStatic src={DOUBLE_ARROW_ICON} width={12} height={12} alt="icon" />
            </a>
          </Box>
        )}
      </Box>
      {isBestProducts &&
        (isMobileV2 ? (
          <ProductPreview slideItems={SlideItems} appendSettings={{ initialSlide: SlideItems?.length < 3 ? 0 : 1 }} />
        ) : (
          <ListProductForPCWrapper gap={gap}
          >
            {children}
          </ListProductForPCWrapper>
        ))}

      {!isBestProducts &&
        (isMobileV2 ? (
          <ListProductForPCWrapper gap={gap}>
            {children}
          </ListProductForPCWrapper>
        ) : (
          <ListProductForPCWrapper gap={gap}>
            {children}
          </ListProductForPCWrapper>
        ))}

      {viewMore && !isBestProducts && (
        <Box
          className={clsx(styles.view_more, {
            [styles.view_more_mv2]: isMobileV2,
          })}
          onClick={() => {
            if (type === 'SAME_MANUFACTURER') {
              gtag.clickViewAllProductSameManufacturer();
            }

            if (type === 'SAME_INGREDIENT') {
              gtag.clickViewAllProductSameIngredient();
            }
          }}
        >
          <LinkComp href={`${redirect}`} color="#0E1983" name="Xem tất cả" />
        </Box>
      )}
    </Paper>
  ) : (
    <>
      {(loading || children?.length > 0) && (
        <div
          className={clsx(isMobile ? styles.wrapper_media_mobile : styles.wrapper_media, productsType === MEGA_SALE && styles.wrapperMega)}
          ref={wrapperRef}
        >
          <div className={styles.wrapper_media_container}>
            <div className={clsx(styles.SliderProductWrap, productsType === MEGA_SALE && styles.backgroundMega)}>
              {productsType === MEGA_SALE && <div className={styles.backgroundTitle} />}
              {productsType === MEGA_SALE ? (
                <h2 className={clsx(styles.title, styles.megaSale_name)}>
                  {icon}
                  <Typography variant="body" component="span" className={styles.contentTitleTop}>
                    {name}
                  </Typography>
                </h2>
              ) : (
                <div className={styles.wrapperTitle}>
                  <h2 className={clsx(styles.title, styles.wrapperContentTitle)}>
                    {icon}
                    <Typography variant="body" component="span" className={styles.contentTitle}>
                      {name}
                    </Typography>
                  </h2>
                  <Box
                    className={styles.seeAllWrapper}
                    onClick={() => {
                      if (productsType === 'THUOC_KE_DON_GIA_TOT') {
                        gtag.clickViewAllThuocKeDonGiaTot({ currentUrl, redirect });
                      }
                    }}
                  >
                    <LinkComp href={`${redirect}`} className={clsx(isMobile ? styles.seeAllMobile : styles.seeAll)}>
                      {MAP_BTN_TITLE_BY_REDIRECT_URL[redirect] || 'Xem tất cả'}
                      <PlayIcon />
                    </LinkComp>
                  </Box>
                </div>
              )}
              {loading ? (
                <Box className={styles.progress}>
                  <CircularProgress size={50} thickness={4} />
                </Box>
              ) : (
                <ListProductForPCWrapper gap={gap}>
					{children}
                  {viewMore && productsType === MEGA_SALE && (
                    <Box className={styles.seeAllWrapperMega}>
                      <LinkComp href={`${redirect}`} className={clsx(isMobile ? styles.seeAllMobile : styles.seeAll)}>
                        {MAP_BTN_TITLE_BY_REDIRECT_URL[redirect] || 'Xem tất cả'}
                      </LinkComp>
                    </Box>
                  )}
                </ListProductForPCWrapper>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListProductWithTitle;
