import create from 'zustand';
import { persist } from 'zustand/middleware';

const INITIAL_STATE = {
  isGuide: 0,
};

const useStartGuide = create(
  persist(
    (set, get) => ({
      ...INITIAL_STATE,
      setGuide: (payload) => {
        set({
          ...get(),
          isGuide: payload,
        });
      },
    }),
    {
      name: 'guide_state',
    },
  ),
);

export default useStartGuide;
