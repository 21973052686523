import { ButtonBase, CircularProgress, Modal } from '@material-ui/core';
import RatingClient from 'clients/RatingClient';
import clsx from 'clsx';
import { ICON_RATING, STAR_FULFILLED, STAR_OUTLINE } from 'constants/Images';
import { useState } from 'react';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import NotifyUtils from 'utils/NotifyUtils';
import styles from './styles.module.css';

const ARR_STAR = [0, 1, 2, 3, 4];

const ImageContainer = ({ indexActive, index, onMouseOver, rating, onClick }) => (
  <div
    className={styles?.wrapperImage}
    onMouseOver={() => onMouseOver(index)}
    onFocus={() => onMouseOver(index)}
    onClick={() => onClick(index)}
    role="button"
  >
    <ImageFallbackStatic src={indexActive > index - 1 || rating > index - 1 ? STAR_FULFILLED : STAR_OUTLINE} width="44" height="44" />
  </div>
);

const ModalRating = ({ open = false, onClose, className, configData }) => {
  const { customerRatingConfig = {}, customerID = 0 } = configData;
  const [enable, setEnable] = useState(true);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(4);
  const [rating, setRating] = useState(4);
  const [content, setContent] = useState('');
  const handleMouseOver = (index) => {
    setIndex(index);
  };
  const handleMouseOut = (e) => {
    e.stopPropagation();
    setIndex(-1);
  };
  const onValueChange = (e) => {
    const { value } = e?.target || '';
    setContent(() => value);
    const conditionHaveValueAndRating = value && rating > -1;
    if (conditionHaveValueAndRating) {
      setEnable(true);
      setError(false);
      return;
    }
    if (rating < 2) {
      setEnable(false);
      setError(true);
    }
  };
  const handleClick = (index) => {
    if (index > 1 || content) {
      setEnable(true);
      setError(false);
    } else {
      setEnable(false);
      setError(true);
    }
    setRating(() => index);
  };
  const handleSubmitRating = async () => {
    if (content?.trim() || rating > 1) {
      setLoading(true);

      const body = {
        customerID,
        type: 'ORDER',
        point: rating + 1,
        feedback: content?.trim() || '',
      };
      const submitRating = await RatingClient.ratingOrder({ body });
      if (submitRating) {
        setLoading(false);
        if (submitRating?.status !== 'OK') {
          NotifyUtils.error(submitRating?.message);
        } else {
          NotifyUtils.success('Cảm ơn bạn đã đánh giá');
          onClose();
        }
      }
    } else {
      setError(true);
    }
  };
  const handleClose = () => {
    localStorage.setItem('time-rating', new Date());
    onClose();
  };
  return (
    <Modal className={clsx(styles.modal, className)} open={open}>
      <div className={styles?.containerModal}>
        <div className={styles?.title}>
          <ImageFallbackStatic src={ICON_RATING} width="60" height="60" objectFit="contain" />
          <span dangerouslySetInnerHTML={{ __html: customerRatingConfig?.ratingTitle || '' }} />
        </div>
        <div className={styles?.body}>
          <span className={styles?.titleBody} dangerouslySetInnerHTML={{ __html: customerRatingConfig?.ratingSubTitle || '' }} />
          <div className={styles?.ratingContainer} onMouseOut={handleMouseOut} onBlur={handleMouseOut} role="button">
            {ARR_STAR?.map((x) => (
              <ImageContainer
                key={x}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onClick={handleClick}
                index={x}
                indexActive={index}
                rating={rating}
              />
            ))}
          </div>
          <textarea
            className={clsx(styles?.textarea, error && styles?.error)}
            onChange={onValueChange}
            value={content}
            placeholder={customerRatingConfig?.ratingPlaceholder || ''}
          />
          {error && <span className={styles?.errorMessage}>Vui lòng đánh giá để hoàn tất</span>}
        </div>
        <div className={styles?.groupButton}>
          <ButtonBase className={styles?.skipButton} onClick={handleClose}>
            Bỏ qua
          </ButtonBase>
          <ButtonBase className={clsx(styles.disabled, enable && !loading && styles?.submit)} onClick={handleSubmitRating}>
            {loading ? <CircularProgress size={24} color="inherit" /> : '  Hoàn Tất'}
          </ButtonBase>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRating;
