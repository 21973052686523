import React from "react";
import {Box} from "@material-ui/core";

export type ListProductForPCWrapperProps = {
	children: React.ReactNode;
	gap?: string
};

export const ListProductForPCWrapper: React.FC<ListProductForPCWrapperProps> = ({children, gap = '20px'}) => {
    return <Box  display={'flex'} gridGap={gap} flexWrap={'wrap'} justifyContent={'center'}> {children}  </Box>;
 };
