import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { DEFAULT_THUOCSI_TITLE } from 'constants/data';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { STATIC_STORAGE } from 'sysconfig';
import { ImageFallbackBanner } from 'utils/ImageFallback';
import { CompanyIntroduction } from '../Company';
import styles from './styles.module.css';

export const imageMediaTopNewUI = [
  {
    id: 1,
    url: `${STATIC_STORAGE}/img/homepage/CAFEBIZ_NEW.jpg`,
    href: 'https://www.seedstarsworld.com/event/seedstars-hochiminh-2018',
    width: '168px',
    height: '76px',
    text: 'Tổng diện tích lên đến 18,000 m2 của 3 kho và trung tâm xử lý đơn hàng tại TP.HCM, Hà Nội, Bình Dương giúp tối ưu hóa hiệu suất và thiết lập mạng lưới kho trung chuyển trên cả nước.',
  },
  {
    id: 2,
    url: `${STATIC_STORAGE}/img/homepage/thanhnien_NEW.jpg`,
    href: 'https://startup.vnexpress.net/tin-tuc/xu-huong/nen-tang-phan-phoi-duoc-pham-an-nen-lam-ra-trong-covid-19-4178711.html',
    width: '200px',
    height: '104px',
    text: '“Mỗi nơi một giá” vẫn luôn là tình trạng chung trong ngành phân phối dược phẩm. Nhận ra được tồn tại này, thuocsi.vn tự tin công khai giá bán sỉ với các nhà thuốc và cơ sở y tế.',
  },
  {
    id: 3,

    url: `${STATIC_STORAGE}/img/homepage/logo_vnexpress_NEW.jpg`,
    href: 'https://www.24h.com.vn/tin-tuc-suc-khoe/thuocsivn-diem-cung-cap-thuoc-si-uy-tin-trong-thoi-dai-so-c683a1373539.html',
    width: '200px',
    height: '200px',
    text: 'Chính nhờ mô hình cung cấp thuốc bằng hệ thống trực tuyến, các nhà thuốc và cơ sở y tế có thể đặt hàng với số lượng bất kỳ và tự quản lý được tình trạng đơn hàng từ khâu chốt đơn đến khâu giao nhận.',
  },
  {
    id: 4,
    url: `${STATIC_STORAGE}/img/homepage/tienphong_NEW.jpg`,
    href: 'http://oi.nttdata.com/en/contest/9th/venue/c05',
    width: '166px',
    height: '203x',
    text: 'Chính nhờ sự phát triển của kênh phân phối online mà thuocsi.vn đã giải quyết được bài toán nhập thuốc khó khăn của các nhà thuốc ở xa trung tâm và thiếu hụt về điều kiện.',
  },
];

export const imageMediaBottomNewUI = [
  {
    id: 1,
    url: `${STATIC_STORAGE}/img/homepage/tech_NEW.jpg`,
    href: 'https://e27.co/our-ho-chi-minh-city-top100-winners-prove-that-the-vietnamese-dragon-is-awake-20190313',
    width: '165px',
    height: '103px',
    dark: true,
    text: 'Launched in 2017, thuocsi.vn runs a business-to-business market place that connects pharmacies, clinics and small drugstores with licensed distributors.',
  },
  {
    id: 2,
    url: `${STATIC_STORAGE}/img/homepage/e27_NEW.jpg`,
    href: 'https://tienphong.vn/gap-go-thuocsi-vn-don-vi-dung-cong-nghe-toi-uu-hoa-va-lam-moi-quy-trinh-phan-phoi-duoc-pham-post1448510.tpo',
    width: '200px',
    height: '100px',
    text: 'As a business-to-business (B2B) marketplace, thuocsi.vn connects healthcare practices with many verified, licensed distributors, which provides automatic matching of orders and end-to-end logistics.',
  },
];

const sliderSettings = {
  slidesToShow: 2,
  slidesToScroll: 1,
  infinite: true,
  arrows: false,
  centerPadding: '60px',
  rows: 2,
  speed: 500,
  centerMode: true,
  autoplay: true,
};

const renderMediaItem = (data) =>
  data.map((item, itemIndex) => (
    <div key={`media-${item.url}`} className={styles.hover_link}>
      <div className={`${styles.box} ${itemIndex % 2 === 1 ? styles.secondRowMargin : ''}`}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <ImageFallbackBanner className={item.dark ? styles.dark : undefined} src={item.url} width={200} height={item.height} layout="fixed" />
          </div>
          <Typography>{item.text}</Typography>
        </div>
      </div>
    </div>
  ));

const News = () => (
  <Container className={styles.media_wrapper}>
    <CompanyIntroduction titleText="Truyền thông nói gì về " branchName={DEFAULT_THUOCSI_TITLE} />
    <div>
      <Slider {...sliderSettings} autoplay={3000}>
        {renderMediaItem(imageMediaTopNewUI)}
        {renderMediaItem(imageMediaBottomNewUI, true)}
      </Slider>
    </div>
  </Container>
);

export default News;
