import { FormControl, Grid } from '@material-ui/core';
import { withLogin } from 'HOC';
import { getData, getFirst, isValid } from 'clients/Clients';
import CustomerClient, { getUserLogGuide, postUserLogGuide } from 'clients/CustomerClient';
import ProductClientV2 from 'clients/ProductClientV2';
import PromoClient from 'clients/PromoClient';
import VideoClient from 'clients/VideoClient';
import WebServiceClient from 'clients/WebServiceClient';
import BannerSliderNew from 'components-v2/mocules/BannerSliderNew';
import DrugGroup from 'components-v2/mocules/DrugGroup';
import ModalRating from 'components-v2/mocules/ModalRating';
import NanoBanner from 'components-v2/mocules/NanoBanner';
import ModalLayout from 'components-v2/mocules/OcrFlowContainer/Components/ModalLayout';
import { ConfirmRemind } from 'components-v2/mocules/OcrFlowContainer/ConfirmContent/ConfirmRemind';
import Home from 'components-v2/organisms/pages/home';
import Template from 'components/layout/Template';
import TemplateV2 from 'components/layout/TemplateV2';
import PopupTourRSLoyaltyPoint from 'components/mocules/PopupGuideRSLoyaltyPoint';
import SliderProductBlock from 'components/mocules/Skeleton/SliderProductBlock';
import HomePageBlock from 'components/organisms/HomePageBlock';
import HomePageBlockV2 from 'components/organisms/HomePageBlockV2';
import MiniBannerBlock from 'components/organisms/MiniBannerBlock';
import { INSIDER_RECOMMENDATION } from 'constants/Enums';
import { DEAL, MEDICAL_DRUG_HOME } from 'constants/Icons';
import { HOME_ICON_MEGA_SALE } from 'constants/Images';
import { LIKE_ICON, NEW_PRODUCT } from 'constants/Images/mobile';
import { PROMO_CODES } from 'constants/Paths';
import { EXPIRED_LOYALTY, MAR_COM, RATING_ORDER } from 'constants/PopupPriority';
import { DEFAULT_THUOCSI_LONG_TITLE } from 'constants/data';
import { GUIDE_FEATURE } from 'constants/data/guideline';
import { usePopup } from 'context/Popup';
import useInsiderPopup from 'hooks/useInsiderPopup';
import useOpenOnDateChange from 'hooks/useOpenOnDateChange';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { doWithServerSide } from 'services/SsrService';
import { IS_UI, SHOW_INSIDER_RECOMMEND, TAG_NEW } from 'sysconfig';
import ImageFallback from 'utils/ImageFallback';
import LiveChat from 'utils/LiveChat';
import MonitorUtils, { MONITORING_COLLECTOR_TYPE, mapScreenToEnum } from 'utils/MonitorUtils';
import { calculateTwoDates } from 'utils/helper';
import { useStore } from 'zustand-lib/storeGlobal';
import useMobileV2 from 'zustand-lib/storeMobile';
import useGetTagPromotion from 'zustand-lib/useGetTagPromotion';
import styles from './styles.module.css';


const HomeProductRecommendationBlock = dynamic(() => import('components/organisms/HomeProductRecommendationBlock'));

const title = DEFAULT_THUOCSI_LONG_TITLE;
const SIZE_ICON = 40;
const SIZE_ICON_MOBILE = 24;

export async function getServerSideProps(ctx) {
  return doWithServerSide(
    ctx,
    async () => {
      return {
        props: {
          SEO_CONFIG: {
            title,
          },
          bytePlusAppID: process.env.BYTEPLUS_APP_ID,
          marketingLiveHostID: process.env.MARKETING_HOST_LIVE_USER_ID,
          insiderUrl: process.env.URL_INSIDER_POPUP,
          projectId: process.env.PROJECT_ID,
        }, // will be passed to the page component as props
      };
    },
    {
      serverSideTranslations,
      namespaces: ['common'],
    },
  );
}

const DynamicWhyBuymed = dynamic(() => import('components/organisms/WhyBuymed'), { ssr: false });
const DynamicPartners = dynamic(() => import('components-v2/organisms/pages/home/Partners'), { ssr: false });
const DynamicSliderComp = dynamic(() => import('components-v2/organisms/pages/home/CustomerSayAbout'), { ssr: false });
const DynamicHomeCTASection = dynamic(() => import('components/organisms/HomeCTASection'), { ssr: false });
const DynamicLiveStream = dynamic(() => import('../../components-v2/mocules/LiveStream'), {
  ssr: false,
});
const ImageIconGoodPrice = () => <ImageFallback src={MEDICAL_DRUG_HOME} width={40} height={40} />;
const INIT_LIVE = { isLive: false, url: '', liveStreamID: null, chatVisible: true, hiddenLive: false, roomID: '', startTimeShifting: '' };
const PCScreen = ({
  isMobile = false,
  bannerStatus = null,
  isAuthenticated = false,
  products = [],
  loading,
  categories = [],
  dataShowBoxYir,
  atLeastLoyaltyPoint,
  loyaltyRSPoint,
  isPopupRSLoyaltyPoints,
  togglePopupRSLoyaltyPoints,
  isOnMovingPromoCode = false,
  toggleMovingPromoCode,
  user,
  activePopup,
  setCanShowNextPopup,
}) => {
  const router = useRouter();
  const [productData, setProductData] = useState({
    RENHAT: [],
    THUOCKEDONGIATOT: [],
    BANCHAY: [],
    NEW: [],
    DEAL: [],
  });
  const [openRating, setOpenRating] = useState();
  const [mediaLive, setMediaLive] = useState(INIT_LIVE);
  const mediaLiveRef = useRef(INIT_LIVE);
  const appSetting = useStore((state) => state.appSetting);

  let timeIntervalMediaLive = null;

  useEffect(() => {
    const productDataMap = new Map();
    products.forEach(({ tags, productDatas }) => {
      tags?.forEach((tag) => {
        const currentSet = productDataMap.get(tag) || new Set();
        productDatas?.forEach((product) => currentSet.add(product));
        productDataMap.set(tag, currentSet);
      });
    });

    productDataMap.forEach((productSet, tag) => {
      productDataMap.set(tag, Array.from(productSet));
    });

    setProductData((prevProductData) => ({
      ...prevProductData,
      RENHAT: [...(productDataMap.get('RENHAT') ?? [])],
      THUOCKEDONGIATOT: [...(productDataMap.get('GY7G') ?? [])],
      BANCHAY: [...(productDataMap.get('BANCHAY') ?? [])],
      NEW: [...(productDataMap.get('V2U1') ?? [])],
      DEAL: [...(products[4]?.productDatas ?? [])],
    }));
  }, [products]);
  useEffect(() => {
    const timeRatingLocal = localStorage.getItem('time-rating');
    if (timeRatingLocal) {
      const calculateDateShowRating = calculateTwoDates(timeRatingLocal);
      const timeHiddenRating = user?.customerRatingConfig?.durationGapMinutes ? user?.customerRatingConfig?.durationGapMinutes / 60 / 24 : 1;
      if (calculateDateShowRating > timeHiddenRating && user?.customerRatingConfig?.showRatingPopup) {
        setOpenRating(true);
      }
    } else {
      setOpenRating(user?.customerRatingConfig?.showRatingPopup);
    }
  }, []);

  const setMediaState = (data) => {
    const mediaHistory = mediaLiveRef.current;

    const stateData = {
      isLive: mediaHistory.isLive,
      url: mediaHistory?.url,
      roomID: mediaHistory?.roomID,
      liveStreamID: mediaHistory?.liveStreamID,
      startTimeShifting: mediaHistory?.startTimeShifting,
    };

    const compareData = {
      isLive: data.isLive,
      url: data?.url,
      roomID: data?.roomID,
      liveStreamID: data?.liveStreamID,
      startTimeShifting: data?.startTimeShifting,
    };

    if (JSON.stringify(stateData) !== JSON.stringify(compareData)) {
      mediaLiveRef.current = compareData;
      setMediaLive((curr) => ({ ...curr, ...data }));
    }
  };

  const onLiveListener = async () => {
    const result = await VideoClient.getCurrentLiveStream();
    if (!isValid(result)) {
      setMediaState(INIT_LIVE);
      return;
    }
    const data = getData(result);
    if (data?.length > 0) {
      const response = data[0];
      if (mediaLiveRef.current.liveStreamID === response?.liveStreamID || !mediaLiveRef.current.liveStreamID) {
        const mediaLiveData = {
          isLive: true,
          url: response?.hlsUrl,
          roomID: response?.roomID,
          liveStreamID: response?.liveStreamID,
          startTimeShifting: response?.startTimeShifting,
        };

        setMediaState(mediaLiveData);
        const metaData = {
          liveStreamID: response?.liveStreamID,
        };
        MonitorUtils.sendLivestreamEvent(MONITORING_COLLECTOR_TYPE.DISPLAY_LIVESTREAM, metaData);
      }
    } else {
      setMediaState(INIT_LIVE);
    }
  };

  const getCurrentViewLiveStream = async () => {
    const liveStreamID = mediaLiveRef.current?.liveStreamID;
    if (!liveStreamID) return 0;

    const result = await VideoClient.getViewCurrentLive({ liveStreamID });
    const currentView = result.data?.[0]?.currentView;
    if (currentView) {
      return currentView;
    }
    return 0;
  };

  const onView = async () => {
    const liveStreamID = mediaLiveRef.current?.liveStreamID;
    if (!liveStreamID) return;
    await VideoClient.reportView({ liveStreamID });
  };

  useEffect(() => {
    onLiveListener();
    timeIntervalMediaLive = setInterval(() => {
      onLiveListener();
    }, 60000);
    return () => {
      clearInterval(timeIntervalMediaLive);
    };
  }, []);
  // tracking monitor
  const trackingMonitorClientClickChangingPointGuide = async () => {
    const event = MONITORING_COLLECTOR_TYPE.CLICK_CHANGING_POINT_GUIDE;
    const metaData = {
      customerId: String(user?.customerID),
      source: 'web-desktop',
      screen: mapScreenToEnum({}, router.pathname),
      description: `Click nút “Đổi điểm” trong popup guide điểm tích lũy`,
    };
    MonitorUtils.rsLoyaltyPointClickEvent(event, metaData);
  };

  const handleConfirmRSLoyaltyPoint = async ({ isConfirm = false }) => {
    const feature = GUIDE_FEATURE.NOTI_RESET_POINT;
    await postUserLogGuide({ feature, isViewAll: isConfirm });

    if (isConfirm) {
      toggleMovingPromoCode(true);
      trackingMonitorClientClickChangingPointGuide();
      router.push({
        pathname: PROMO_CODES,
        query: { isScroll: true },
      });
    } else {
      togglePopupRSLoyaltyPoints(false);
    }
  };
  return (
    <>
      {isAuthenticated && mediaLive.isLive && mediaLive.url && mediaLive.liveStreamID && (
        <Grid container className={styles.boxChatContainer}>
          <DynamicLiveStream
            onCount={getCurrentViewLiveStream}
            onView={onView}
            urlPull={mediaLive.url}
            chat={<LiveChat liveStreamID={mediaLive?.liveStreamID} />}
            startTime={mediaLive.startTimeShifting}
          />
        </Grid>
      )}
      <div className={styles.containerBanner}>
        <Grid container className={styles.boxContainer}>
          <Grid item xs={12} sm={7}>
            {bannerStatus && bannerStatus === 'ON' && <BannerSliderNew isMobile={isMobile} />}
          </Grid>
          <Grid item xs={12} sm={5}>
            <NanoBanner dataShowBoxYir={dataShowBoxYir} />
          </Grid>
        </Grid>
      </div>
      {!isAuthenticated ? (
        <>
          <MiniBannerBlock isMobile={isMobile} />
          <DynamicWhyBuymed />
          <DynamicHomeCTASection />
        </>
      ) : (
        <div className="SliderProductWrap">
          {isAuthenticated && loading && (
            <>
              <SliderProductBlock />
              <SliderProductBlock />
              <SliderProductBlock />
              <SliderProductBlock />
            </>
          )}

          {productData.RENHAT?.length > 0 && (
            <HomePageBlockV2
              name={appSetting?.HOME_PRODUCTS_BLOCK_CONFIG?.RE_NHAT?.title || 'Giá tốt nhất thị trường'}
              viewMore
              redirectUrl={appSetting?.HOME_PRODUCTS_BLOCK_CONFIG?.RE_NHAT?.url || '/products'}
              type="MEGA-SALE"
              icon={<ImageFallback src={HOME_ICON_MEGA_SALE} width={40} height={40} />}
              product={productData.RENHAT}
              isMobile={isMobile}
            />
          )}
          {categories.isLoaing ? <SliderProductBlock /> : <DrugGroup isMobile={isMobile} categories={categories} />}
          {productData.THUOCKEDONGIATOT?.length > 0 && (
            <HomePageBlockV2
              name="Thuốc kê đơn giá tốt"
              viewMore
              redirectUrl="/products?currentTab=thuoc-ke-don"
              product={productData.THUOCKEDONGIATOT}
              icon={<ImageIconGoodPrice />}
              isMobile={isMobile}
            />
          )}

          <MiniBannerBlock isMobile={isMobile} />
          {productData.BANCHAY?.length > 0 && (
            <HomePageBlockV2
              name="Sản phẩm bán chạy"
              viewMore
              redirectUrl="/products?currentTab=san-pham-ban-chay"
              type="BANCHAY"
              product={productData.BANCHAY}
              icon={
                <ImageFallback src={LIKE_ICON} width={isMobile ? SIZE_ICON_MOBILE : SIZE_ICON} height={isMobile ? SIZE_ICON_MOBILE : SIZE_ICON} />
              }
              isMobile={isMobile}
            />
          )}

          {productData.DEAL?.length > 0 && (
            <HomePageBlockV2
              name="Sản phẩm khuyến mãi"
              icon={<ImageFallback src={DEAL} width={isMobile ? SIZE_ICON_MOBILE : SIZE_ICON} height={isMobile ? SIZE_ICON_MOBILE : SIZE_ICON} />}
              redirectUrl="/khuyenmai"
              isMobile={isMobile}
              product={productData.DEAL}
            />
          )}

          {productData.NEW?.length > 0 && (
            <HomePageBlockV2
              name="Sản phẩm mới"
              icon={
                <ImageFallback src={NEW_PRODUCT} width={isMobile ? SIZE_ICON_MOBILE : SIZE_ICON} height={isMobile ? SIZE_ICON_MOBILE : SIZE_ICON} />
              }
              type={TAG_NEW}
              redirectUrl="/products?currentTab=san-pham-moi&tag=V2U1&page=1&sort=NEWEST"
              isMobile={isMobile}
              product={productData.NEW}
            />
          )}

          {SHOW_INSIDER_RECOMMEND && (
            <HomePageBlock name="Sản phẩm gợi ý" viewMore type={INSIDER_RECOMMENDATION.MOST_VIEWED} redirectUrl="/products" />
          )}

		  <HomeProductRecommendationBlock isAuthenticated={isAuthenticated} products={products} />

          {user?.customerRatingConfig && activePopup === RATING_ORDER ? (
            <ModalRating
              open={openRating}
              onClose={() => {
                setOpenRating(false);
                setCanShowNextPopup();
              }}
              configData={user}
            />
          ) : null}
        </div>
      )}
      {/* 1st */}
      {loyaltyRSPoint > 0 && activePopup === EXPIRED_LOYALTY ? (
        <PopupTourRSLoyaltyPoint
          open={isPopupRSLoyaltyPoints}
          handleConfirm={() => handleConfirmRSLoyaltyPoint({ isConfirm: true })}
          handleClose={() => handleConfirmRSLoyaltyPoint({ isConfirm: false })}
          loyaltyRsPoint={loyaltyRSPoint}
          isMoving={isOnMovingPromoCode}
          atLeastLoyaltyPoint={atLeastLoyaltyPoint}
        />
      ) : null}

      <DynamicPartners isLogin />
      <DynamicSliderComp isLogin />
    </>
  );
};

const pageName = 'home';
const pageTitle = 'Trang chủ';
const bannerStatus = 'ON';

const LandingPage = (props) => {
  const { isMobile, isAuthenticated, user, bytePlusAppID, marketingLiveHostID, insiderUrl, projectId } = props;
  const { provinceCode, isWelcomeBack, isRequiredCertificates = false } = user || {};
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const toggleFeature = useStore((state) => state.toggleFeature);
  const toggleBeta = useMobileV2((state) => state.toggleBeta);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState({ data: [], isLoading: false });
  const [dataYir, setDataYir] = useState();
  const [dataShowBoxYir, setDataShowBoxYir] = useState();
  // res loyalty point
  const [rsLoyaltyPoint, setRsLoyaltyPoint] = useState();
  const [atLeastLoyaltyPoint, setAtLeastLoyaltyPoint] = useState(0);
  const [isPopupRSLoyaltyPoints, togglePopupRSLoyaltyPoints] = useState(true); // set true for first priority guide
  const [isOnMovingPromoCode, toggleMovingPromoCode] = useState(false);
  // handle check remind popup if user chưa có giấy phép
  const { setDisplayPopup, isShowNextPopup } = useInsiderPopup(user, insiderUrl, projectId);
  const { activePopup, setCanShowNextPopup } = usePopup();
  const [isOpen, close] = useOpenOnDateChange(isRequiredCertificates, isAuthenticated, activePopup, setCanShowNextPopup);
  const handleClose = () => {
    if (isAuthenticated) {
      close();
    }
  };
  const router = useRouter();
  const {
    query: { trial = false, chatTs },
  } = router || {};
  useEffect(() => {
    if (!isMobileV2 && trial) {
      toggleBeta();
    }
  }, [trial]);
  useEffect(() => {
    if (activePopup === MAR_COM) {
      setDisplayPopup(true);
    }
  }, [activePopup]);

  // promotion gift tag, call one place  on time use anywhere
  const { getPromoLists } = useGetTagPromotion();
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    getPromoLists({ getVoucherInfo: false, signal });

    return () => controller.abort();
  }, [isAuthenticated]);

  const fetchDataCategories = async () => {
    if (!isAuthenticated) {
      return;
    }

    setCategories((previousState) => ({ ...previousState, isLoading: true }));
    const categoriesRes = await WebServiceClient.getCategories({});

    if (!isValid(categoriesRes)) {
      setCategories({ data: [], isLoading: false });
      return;
    }

    const data = getData(categoriesRes);
    setCategories({ data, isLoading: false });
  };
  useEffect(() => {
    const fetchData = async () => {
      fetchDataCategories();
      setLoading(true);
      const productsRes = await ProductClientV2.getProductGroupsClient();

      if (!isValid(productsRes)) {
        setLoading(false);
        return;
      }
      const data = getData(productsRes);
      setProducts(data);
      setLoading(false);
    };
    fetchData();
  }, [isAuthenticated]);
  useEffect(() => {
    if (chatTs != null) toggleFeature('chatTs', chatTs, { isCookie: true });
  }, [chatTs]);

  useEffect(async () => {
    const { query } = router;
    if (query?.yearreview && isAuthenticated) {
      const getYearInReview = await WebServiceClient.getYearInReview({});
      if (isValid(getYearInReview)) {
        setDataYir(getYearInReview);
      } else {
        setDataShowBoxYir({ campaignBoxId: getYearInReview?.data?.[0]?.webMobileCampaignBoxID || '' });
        localStorage.setItem(
          'finishYir',
          JSON.stringify({
            isFinish: true,
            notFound: true,
            customerID: user?.customerID,
            campaignBoxId: getYearInReview?.data?.[0]?.webMobileCampaignBoxID,
          }),
        );
      }
    }
  }, [router, isAuthenticated]);

  // guide reset Loyalty point
  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const [respListLoyalty, respRsLoyaltyPoint, respCheckGuideRSPoint] = await Promise.all([
          PromoClient.getSelfLoyaltyList({}),
          CustomerClient.getUserResetLoyaltyPoint({}),
          getUserLogGuide({
            q: {
              feature: GUIDE_FEATURE.NOTI_RESET_POINT,
            },
          }),
        ]);

        // get rs loyalty point
        if (respRsLoyaltyPoint?.status === 'OK') {
          setRsLoyaltyPoint(getFirst(respRsLoyaltyPoint) || 0);
        } else {
          setRsLoyaltyPoint(0);
        }
        // get minimum loyalty point on list
        if (respListLoyalty?.status === 'OK') {
          const atLeastLoyaltyPoint = Math.min.apply(
            0,
            getData(respListLoyalty).map((item) => item?.point),
          );

          setAtLeastLoyaltyPoint(atLeastLoyaltyPoint || 0);
        } else {
          setAtLeastLoyaltyPoint(0);
        }

        const dataCheckGuide = getFirst(respCheckGuideRSPoint);
        const condRsLoyaltyPoint = respRsLoyaltyPoint && getFirst(respRsLoyaltyPoint) > 0;
        if (dataCheckGuide?.isActive && !dataCheckGuide?.isGuided && condRsLoyaltyPoint) {
          togglePopupRSLoyaltyPoints(true);
        } else {
          togglePopupRSLoyaltyPoints(false);
        }
      })();
    }
  }, [isAuthenticated]);

  return (
    <>
      {IS_UI && !isAuthenticated ? (
        <TemplateV2 isMobile={isMobile} pageName={pageName} pageTitle={pageTitle} showTopSearchMV2 chatTs={chatTs} isWelcomeBack={isWelcomeBack}>
          <Home
            isMobile={isMobile}
            bannerStatus={bannerStatus}
            isAuthenticated={isAuthenticated}
            provinceCode={provinceCode}
            products={products}
            loading={loading}
          />
        </TemplateV2>
      ) : (
        <Template isMobile={isMobile} pageName={pageName} pageTitle={pageTitle} showTopSearchMV2 chatTs={chatTs} isWelcomeBack={isWelcomeBack}>
          <PCScreen
            categories={categories}
            isMobile={isMobile}
            bannerStatus={bannerStatus}
            isAuthenticated={isAuthenticated}
            provinceCode={provinceCode}
            products={products}
            loading={loading}
            dataYir={dataYir}
            dataShowBoxYir={dataShowBoxYir}
            atLeastLoyaltyPoint={atLeastLoyaltyPoint}
            loyaltyRSPoint={rsLoyaltyPoint} // reset loyalty point on this year
            isPopupRSLoyaltyPoints={isPopupRSLoyaltyPoints}
            togglePopupRSLoyaltyPoints={togglePopupRSLoyaltyPoints}
            isOnMovingPromoCode={isOnMovingPromoCode}
            toggleMovingPromoCode={toggleMovingPromoCode}
            user={user}
            isShowNextPopup={isShowNextPopup}
            activePopup={activePopup}
            setCanShowNextPopup={setCanShowNextPopup}
          />
          <ModalLayout visible={isOpen} onClose={handleClose}>
            <FormControl fullWidth>
              <ConfirmRemind onClose={handleClose} />
            </FormControl>
          </ModalLayout>
        </Template>
      )}
    </>
  );
};

export default withLogin(LandingPage, false);
