import { useEffect, useState } from 'react';

const REMIND_OCR = 'REMIND_OCR';

const useOpenOnDateChange = (isRequiredCertificates, isAuthenticated, activePopup, setCanShowNextPopup) => {
  const [open, setOpen] = useState(null);
  useEffect(() => {
    if (activePopup === REMIND_OCR) {
      if (isAuthenticated && isRequiredCertificates) {
        const now = new Date();
        const todayString = now.toISOString().split('T')[0];
        const lastOpenRemindDate = localStorage?.getItem('lastOpenRemindDate');
        if (todayString !== lastOpenRemindDate) {
          setOpen(true);
          localStorage.setItem('lastOpenRemindDate', todayString);
          localStorage.setItem('keyToggleOcrPopup', 'false');
        } else {
          setCanShowNextPopup();
        }
      } else {
        setCanShowNextPopup();
      }
    }
  }, [isRequiredCertificates, isAuthenticated, activePopup]);

  return [open, () => setOpen(false)];
};

export default useOpenOnDateChange;
