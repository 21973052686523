import Input from '@mui/material/Input';
import clsx from 'clsx';
import RemoveProductModal from 'components/organisms/RemoveProductModal';
import useCartControlTable from 'hooks/useCartControlTable';
import { useRef } from 'react';
import styles from './styles.module.css';

const CartIcon = () => (
  <div className={styles.inputVisible}>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M14.3608 3.9375H4.00136L3.11622 2.35095C3.01687 2.17287 2.82892 2.0625 2.625 2.0625H1.5C1.18934 2.0625 0.9375 2.31434 0.9375 2.625C0.9375 2.93566 1.18934 3.1875 1.5 3.1875H2.2947L3.16822 4.75324L5.18278 9.21066L5.18484 9.21521L5.36228 9.60781L3.33965 11.7653C3.19422 11.9204 3.14869 12.1443 3.22199 12.3439C3.29529 12.5435 3.47488 12.6847 3.68614 12.7089L5.52974 12.9196C7.83575 13.1831 10.1643 13.1831 12.4703 12.9196L14.3139 12.7089C14.6225 12.6736 14.8442 12.3948 14.8089 12.0861C14.7736 11.7775 14.4948 11.5559 14.1861 11.5911L12.3425 11.8018C10.1214 12.0557 7.87861 12.0557 5.65749 11.8018L4.92585 11.7182L6.41038 10.1347C6.42474 10.1194 6.43808 10.1035 6.45039 10.0871L7.015 10.1605C7.8062 10.2635 8.60596 10.2837 9.40134 10.2208C11.2564 10.074 12.9758 9.19131 14.1761 7.7693L14.6097 7.25569C14.6242 7.23844 14.6377 7.22033 14.6501 7.20146L15.4581 5.97007C16.0309 5.09724 15.4048 3.9375 14.3608 3.9375Z"
        fill="#999999"
      />
      <path
        d="M4.875 13.875C4.25368 13.875 3.75 14.3787 3.75 15C3.75 15.6213 4.25368 16.125 4.875 16.125C5.49632 16.125 6 15.6213 6 15C6 14.3787 5.49632 13.875 4.875 13.875Z"
        fill="#999999"
      />
      <path
        d="M12 15C12 14.3787 12.5037 13.875 13.125 13.875C13.7463 13.875 14.25 14.3787 14.25 15C14.25 15.6213 13.7463 16.125 13.125 16.125C12.5037 16.125 12 15.6213 12 15Z"
        fill="#999999"
      />
    </svg>
  </div>
);

const NewIconMinus = ({ handleDecrease, currentValue }) => (
  <div onClick={handleDecrease} className={styles.minusContainerNew} role="button">
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
      <path d="M7 12H17" stroke={currentValue === 0 ? '#999999' : '#09884D'} strokeWidth="2" strokeLinecap="round" />
    </svg>
  </div>
);

const IconMinus = ({ handleDecrease }) => (
  <div onClick={handleDecrease} className={styles.minusContainer} role="button">
    <div className={styles.minus} />
  </div>
);

const NewIconAdd = ({ handleIncrease, currentValue, maxQuantityConsumed }) => (
  <div onClick={handleIncrease} className={clsx(styles.addContainerNew)} role="button">
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
      <path d="M12 17V7" stroke={currentValue >= maxQuantityConsumed ? '#999999' : '#09884D'} strokeWidth="2" strokeLinecap="round" />
      <path d="M7 12L17 12" stroke={currentValue >= maxQuantityConsumed ? '#999999' : '#09884D'} strokeWidth="2" strokeLinecap="round" />
    </svg>
  </div>
);

const IconAdd = ({ handleIncrease, currentValue, maxQuantityConsumed }) => (
  <div onClick={handleIncrease} className={clsx(styles.addContainer, currentValue >= maxQuantityConsumed && styles.disabled)} role="button">
    <div className={styles.addIcon} />
  </div>
);

const QuantityControlTable = ({ product, className, isBuy, newCard, onMinus, onChange, onAdd }) => {
  const refValue = useRef(null);
  const [handleIncrease, handleBlurInput, handleChangeInput, handleDecrease, confirmRemove, closeModal, isShowModalRemove, currentValue] =
    useCartControlTable(product, refValue);
  const handleCancel = (e) => {
    e.stopPropagation();
    closeModal();
  };
  const handleRemove = () => {
    confirmRemove();
  };

  return (
    <div className={styles.maxWidth}>
      <div className={clsx(styles.container, isBuy && styles.isBuy, className && className)}>
        {newCard ? (
          <NewIconMinus
            currentValue={currentValue}
            handleDecrease={(e) => {
              handleDecrease(e);
              onMinus?.(e);
            }}
          />
        ) : (
          <IconMinus
            handleDecrease={(e) => {
              handleDecrease(e);
              onMinus?.(e);
            }}
          />
        )}

        {newCard ? (
          <div className={styles.groupInput}>
            <div className={styles.cartIconContainer}>
              <CartIcon />
              <span className={styles?.currentValue}>{currentValue}</span>
            </div>
            <Input
              className={styles?.newCardInput}
              inputRef={refValue}
              onBlur={handleBlurInput}
              onChange={(e) => {
                handleChangeInput(e);
                onChange?.(e);
              }}
              type="number"
              disableUnderline
            />
          </div>
        ) : (
          <Input
            inputRef={refValue}
            onBlur={handleBlurInput}
            onChange={(e) => {
              onChange?.(e);
              handleChangeInput(e);
            }}
            type="number"
            disableUnderline
          />
        )}

        {newCard ? (
          <NewIconAdd
            handleIncrease={(e) => {
              handleIncrease(e);
              onAdd?.(e);
            }}
            currentValue={currentValue}
            maxQuantityConsumed={product?.consumedMaxQuantity?.maxQuantityConsumed}
          />
        ) : (
          <IconAdd
            handleIncrease={(e) => {
              handleIncrease(e);
              onAdd?.(e);
            }}
            currentValue={currentValue}
            maxQuantityConsumed={product?.consumedMaxQuantity?.maxQuantityConsumed}
          />
        )}
        <RemoveProductModal product={product} visible={isShowModalRemove} onClose={handleCancel} onRemove={handleRemove} />
      </div>
    </div>
  );
};

export default QuantityControlTable;

