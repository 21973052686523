import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, FormControl, InputAdornment, NativeSelect, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIconOutlined from '@material-ui/icons/ErrorOutlined';
import WebServiceClient from 'clients/WebServiceClient';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import { ENUM_SCOPE, ENUM_SCOPE_LABEL } from 'constants/Enums';
import { HTTP_STATUS } from 'constants/Enums/http';
import { ICON_CHECK_IN_PIN, ICON_CITY_NEW, ICON_HOME_NEW, ICON_PHONE_NEW, ICON_SEARCH_NEW, ICON_USER_NEW } from 'constants/Icons';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AddressService from 'services/AddressService';
import { CIRCA_ORIGIN } from 'sysconfig';
import NotifyUtils from 'utils/NotifyUtils';
import ValidateUtils from 'utils/ValidateUtils';
import regUtils from 'utils/regUtils';
import * as yup from 'yup';
import styles from '../styles.module.css';

const { validateData } = ValidateUtils;
const { PHONE_REGEX } = regUtils;
const validateSignUp = ({ name, phone }, failCallback) => {
  try {
    validateData.name(name);
    validateData.phoneNumber(phone);
    return true;
  } catch (error) {
    NotifyUtils.error(error?.message || 'Đã có lỗi xảy ra');
    failCallback(error);
    return false;
  }
};

const InfoBanner2 = React.memo(() => {
  const [provinces, setProvinces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const router = useRouter();
  const schema = yup
    .object({
      name: yup.string().required('Bạn chưa điền họ và tên').min(2, 'Tên đăng nhập phải có ít nhất 2 ký tự'),
      phone: yup.string().required('Bạn chưa điền số điện thoại').matches(PHONE_REGEX, 'Số điện thoại không đúng định dạng'),
    })
    .required();
  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    const getListProvince = async () => {
      try {
        const provinceResult = await AddressService.getProvinces();
        const updatedProvinces = [{ label: 'Tỉnh/thành phố', value: '' }, ...provinceResult];
        setProvinces(updatedProvinces);
        reset({ provinceCode: updatedProvinces[0]?.value });
      } catch (error) {
        NotifyUtils.error('Lấy danh sách tỉnh thành không thành công');
      }
    };
    getListProvince();
  }, []);

  const handleSubmitSignUp = useCallback(async (data) => {
    const isFormValid = validateSignUp(data, (error) => {
      if (error) {
        const newError = {};
        newError[error.type] = true;
      }
    });

    if (!isFormValid) {
      return;
    }
    // patient => redirect to circa
    if (data?.scope === ENUM_SCOPE.PATIENT) {
      const gotoParams = {
        name: data?.name,
        phone_number: data?.phone,
        source_type: 'thuocsi',
        action: 'register',
      };

      const params = new URLSearchParams(gotoParams).toString();
      window.location.replace(`${CIRCA_ORIGIN}?${params}`);
      return;
    }

    setLoading(true);
    let params = {};
    params = {
      ...data,
    };
    const { utm_source: utmSource, utm_medium: utmMedium } = router.query;
    params = {
      ...params,
      group: ['(not set)', '(direct)', 'notset', 'not set', 'direct', undefined, ''].includes(utmSource) ? 'Organic' : 'Marketing',
      utmSource: !utmSource ? '' : utmSource,
      utmMedium: !utmMedium ? '' : utmMedium,
    };
    try {
      const result = await WebServiceClient.contactMe({ body: params });
      if (result?.status === HTTP_STATUS.Ok) {
        setFormVisible(false);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const labels = useMemo(
    () => [
      { label: 'Bạn là...', value: '' },
      ...Object.keys(ENUM_SCOPE).map((key) => ({
        label: ENUM_SCOPE_LABEL[key],
        value: key,
      })),
    ],
    [ENUM_SCOPE, ENUM_SCOPE_LABEL],
  );

  const IconSearch = (
    <InputAdornment position="start" className={styles.input_icon}>
      <ICON_SEARCH_NEW />
    </InputAdornment>
  );
  const IconAccount = (
    <InputAdornment position="start" className={styles.input_icon}>
      <ICON_USER_NEW />
    </InputAdornment>
  );

  const IconPhone = (
    <InputAdornment position="start" className={styles.input_icon}>
      <ICON_PHONE_NEW />
    </InputAdornment>
  );
  const IconLocationCity = (
    <InputAdornment position="start" className={styles.input_icon}>
      <ICON_CITY_NEW />
    </InputAdornment>
  );

  const IconLocationHome = (
    <InputAdornment position="start" className={styles.input_icon}>
      <ICON_HOME_NEW />
    </InputAdornment>
  );

  const IconLocation = (
    <InputAdornment position="start" className={styles.input_icon}>
      <ICON_CHECK_IN_PIN />
    </InputAdornment>
  );
  const options = useMemo(
    () =>
      provinces.map((item) => (
        <option value={item.value} key={item.value}>
          {item.label}
        </option>
      )),
    [provinces],
  );

  return formVisible ? (
    <div className={styles.form}>
      <form className={styles.warpForm} onSubmit={handleSubmit(handleSubmitSignUp)}>
        <Typography className={styles.nameContact}>Liên hệ đặt hàng</Typography>
        <Controller
          name="note"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Input className={styles.search} startAdornment={IconSearch} placeholder="Bạn đang tìm sản phẩm gì?" variant="outlined" {...field} />
            </FormControl>
          )}
        />

        <Controller
          name="name"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Input
                className={styles.input}
                startAdornment={IconAccount}
                placeholder="Họ Và Tên*"
                variant="outlined"
                error={!!errors.name || false}
                data-test="signup-username"
                {...field}
              />
              {errors.name?.message && (
                <div className={styles.text_error} data-test="signup-err-phone">
                  <ErrorIconOutlined />
                  {errors.name?.message}
                </div>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="phone"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Input
                className={styles.input}
                startAdornment={IconPhone}
                placeholder="Số Điện Thoại*"
                variant="outlined"
                error={!!errors.phone || false}
                {...field}
              />
              {errors.phone?.message && (
                <div className={styles.text_error}>
                  <ErrorIconOutlined /> {errors.phone?.message}
                </div>
              )}
            </FormControl>
          )}
        />
        <Controller
          name="scope"
          defaultValue={labels[0]?.value}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <NativeSelect
                IconComponent={ExpandMoreIcon}
                input={<Input data-test="signup-provinceCode" startAdornment={IconLocationHome} />}
                className={styles.province}
                {...field}
              >
                {labels.map((item) => (
                  <option value={item.value} key={item.value}>
                    {item?.label}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          )}
        />
        <Controller
          name="provinceCode"
          defaultValue={provinces[0]?.value}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <NativeSelect
                IconComponent={ExpandMoreIcon}
                input={<Input data-test="signup-provinceCode" startAdornment={IconLocationCity} />}
                className={styles.province}
                {...field}
              >
                {options}
              </NativeSelect>
            </FormControl>
          )}
        />

        <Controller
          name="address"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Input startAdornment={IconLocation} defaultValue="" placeholder="Địa chỉ" variant="outlined" className={styles.input} {...field} />
            </FormControl>
          )}
        />

        <Button className={styles.btn_register} type="submit" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Liên hệ ngay'}
        </Button>
      </form>
    </div>
  ) : (
    <div className={styles.box2}>
      <CheckCircleIcon className={styles.icon} />
      <div className={styles.cmNQuKhchLinHParent}>
        <div className={styles.cmNQu1}>Cảm ơn Quý Khách đã liên hệ</div>
        <div className={styles.nhnVinThuocsivnContainer}>
          <p className={styles.nhnVinThuocsivn1}>{`Nhân viên thuocsi.vn sẽ liên hệ lại Quý Khách `}</p>
          <p className={styles.nhnVinThuocsivn1}>trong vòng 24 giờ.</p>
        </div>
      </div>
    </div>
  );
});

export default InfoBanner2;
