import { VIDEO_SERVICE } from 'constants/APIUriV2';
import { GET, POST } from './Clients';

const getCurrentLiveStream = () => {
    const url = VIDEO_SERVICE.GET_CURRENT_LIVESTREAM;
    return GET({ url });
};

const getViewCurrentLive = (body) => {
  const url = VIDEO_SERVICE.GET_VIEW_CURRENT;
  return POST({ url, body });
};

const getBytePlusToken = (body) => {
    const url = VIDEO_SERVICE.GET_BYTEPLUS_TOKEN;
    return POST({url,body});
};

const reportView = (body) => {
  const url = VIDEO_SERVICE.REPORT_VIEW;
  return POST({ url, body });
};

export default {
    getCurrentLiveStream,
    getBytePlusToken,
    getViewCurrentLive,
    reportView
};
