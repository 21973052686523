import create from 'zustand';

const getWithExpiry = (key) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

// eslint-disable-next-line consistent-return
const setWithExpiry = (key, value, ttl) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const useSettingLocalStorage = create((set) => ({
  openDownload: getWithExpiry('openDownload') === null,
  setOpenDownload: (value, ttl) => {
    setWithExpiry('openDownload', value, ttl);
    set(() => ({ openDownload: value === null }));
  },
}));
export default useSettingLocalStorage;
